import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import LoadingModal from '../../LoadingModal';
import ErrorMessage from '../../ErrorMessage';
import Message from '../../../services/Message';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import authService from '../../../services/AuthService';
import CompanyService from './CompanyService';
import CompanyForm from './CompanyForm';

function isNotFoundError(error: unknown): boolean {
  const httpError = error as { response: { status: number } };
  return !!httpError?.response?.status;
}

function CompanySettingsEdit({ history }: InferProps<typeof CompanySettingsEdit.propTypes>): JSX.Element {
  const { t } = useTranslation();

  const companyService = new CompanyService();
  const userAuthCompanyId = authService.tenantId();

  const { data, isFetching, isError, error } = companyService.useGet(userAuthCompanyId, false);

  const useUpdateCompany = companyService.useUpdate();

  const onSubmit = async (values: FieldValues): Promise<void> => {
    try {
      await useUpdateCompany.mutateAsync({ id: userAuthCompanyId, ...values });
      Message.success(t('company-settings.updated'));
    } catch (error) {
      Message.error(t('company-settings.updated-error'));
      console.error('Error on save: ', error);
    }
  };

  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  if (isError && !isNotFoundError(error)) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <h1>
          <Trans i18nKey="company-settings.edit-title">Update company settings</Trans>
        </h1>
      </GridCell>
      <GridCell span={12}>
        <CompanyForm onFormSubmit={onSubmit} onCancel={onCancel} isLoading={useUpdateCompany.isLoading} defaultValues={data}></CompanyForm>
      </GridCell>
    </Grid>
  );
}

CompanySettingsEdit.propTypes = {
  history: PropTypes.any
};

export default withRouter(CompanySettingsEdit);
