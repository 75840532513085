import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';

import { Grid, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import Breadcrumb from '../common/Breadcrumb';
import StepJourneyForm from './StepJourneyForm';
import Message from '../../services/Message';
import StepJourneyService from './StepJourneyService';
import LoadingModal from '../LoadingModal';
import ErrorMessage from '../ErrorMessage';
import JourneyService from './JourneyService';

function StepJourneyNew({ history }: InferProps<typeof StepJourneyNew.propTypes>): JSX.Element {
  const { journeyId } = useParams() as { journeyId: string };
  const { t } = useTranslation();

  const journeyService = new JourneyService();
  const stepJourneyService = new StepJourneyService(journeyId);
  const useCreateStepJourney = stepJourneyService.useCreate();

  const { data, isFetching, isError, error } = journeyService.useGet(journeyId);

  const onCancel = async (): Promise<void> => {
    history.goBack();
  };

  // const onReady = (): void => {
  //   console.log('onReady - editor is ready');
  // };

  // const onLoad = (): void => {
  //   console.log('onLoad - editor instance is created');
  //   const templateJson = {}; // you can load your template here;
  //   emailEditorRef?.current.editor.loadDesign(templateJson);
  // };

  const onSubmit = async (values: FieldValues): Promise<void> => {
    try {
      await useCreateStepJourney.mutateAsync(values);
      Message.success(t('journey-step.created'));
      history.push(`/journeys/${journeyId}`);
    } catch (error) {
      Message.error(t('journey-step.created-error'));
      console.error('Error on save: ', error);
    }
  };

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb
          routeSegments={[
            { name: t('navlink.journeys'), path: '/journeys' },
            { name: t('journey.view-title'), path: `/journeys/${journeyId}` },
            { name: t('journey-step.new-title') }
          ]}
        />
      </GridCell>

      <GridCell span={12}>
        <h1>
          <Trans i18nKey="journey-step.new-title">New journey</Trans>
        </h1>
      </GridCell>

      <GridCell span={12}>
        <StepJourneyForm journeyId={journeyId} journey={data} onFormSubmit={onSubmit} onCancel={onCancel} isLoading={useCreateStepJourney.isLoading}></StepJourneyForm>
      </GridCell>
    </Grid>
  );
}

StepJourneyNew.propTypes = {
  history: PropTypes.any
};

export default StepJourneyNew;
