import HttpRepository from '../../services/HttpRepository/HttpRepository';

export enum JourneyFlowType {
  Onboarding = 'ONBOARDING',
  Offboarding = 'OFFBOARDING',
  Communication = 'COMMUNICATION'
}

export interface Journey {
  id?: string;
  flow_type: JourneyFlowType;
  name: string;
  description?: string;
}

class JourneyRepository extends HttpRepository<Journey> {
  constructor() {
    super({ basePath: '/journeys' });
  }

  public async createJourneyCopy(id: string): Promise<Journey> {
    const { data } = await super.postPath(`${id}/create-copy`, {});
    return data;
  }
}

export default JourneyRepository;
