import React from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ListDivider, Button, Typography, Select, Elevation } from 'rmwc';
import { fullWithStyle, BlockTitleActions } from '../../StepJourneyForm';
import { TextQuestion } from '../FieldTypes/TextQuestion';

export enum SequenceContentTypes {
  PdfFile = 'PDF-FILE',
  Video = 'VIDEO',
  Url = 'URL',
  Image = 'IMAGE',
  Text = 'TEXT'
}

export function SequenceBlockType({
  control,
  block,
  index,
  blocksLength,
  errors,
  isLoading,
  handleRemoveBlock,
  handleMoveUpBlock,
  handleMoveDownBlock,
  handleAddSequence,
  handleOpenPreviewPopup
}: {
  control: Control;
  block: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  index: number;
  blocksLength: number;
  errors: FieldErrors;
  isLoading: boolean;
  handleRemoveBlock: (index: number, name: string) => void;
  handleMoveUpBlock: (index: number) => void;
  handleMoveDownBlock: (index: number) => void;
  handleAddSequence: () => void;
  handleOpenPreviewPopup: () => void;
}): JSX.Element {
  const { t } = useTranslation();

  const blockContentTypeOptions = [
    {
      label: t('journey-step.form.blocks.sequence.content_type.title_and_text', 'Title and content text'),
      value: SequenceContentTypes.Text
    },
    {
      label: t('journey-step.form.blocks.sequence.content_type.pdf_file', 'PDF File'),
      value: SequenceContentTypes.PdfFile
    },
    {
      label: t('journey-step.form.blocks.sequence.content_type.video', 'Video'),
      value: SequenceContentTypes.Video
    },
    {
      label: t('journey-step.form.blocks.sequence.content_type.url', 'Url'),
      value: SequenceContentTypes.Url
    },
    {
      label: t('journey-step.form.blocks.sequence.content_type.image', 'Image'),
      value: SequenceContentTypes.Image
    }
  ];

  return (
    <>
      <div style={{ padding: '0 10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {index === 0 && (
          <>
            <Typography use="body1" tag="p" style={{ margin: '0', padding: '10px 0' }}>
              <Trans i18nKey="journey-step.form.blocks.sequence.title">Sequence</Trans>
            </Typography>
            <Button raised trailingIcon="visibility" onClick={handleOpenPreviewPopup}>
              <Trans i18nKey="journey-step.preview.open-button-label">Visualize</Trans>
            </Button>
          </>
        )}
      </div>
      {index === 0 && <ListDivider />}
      <div style={{ padding: '10px' }}>
        {index === 0 && (
          <TextQuestion
            control={control}
            property="start_button"
            labelProps={{
              labelText: 'Sequence button label',
              htmlFor: `message_blocks.${index}.start_button`,
              i18nKey: 'journey-step.form.blocks.sequence.start_button.label'
            }}
            controllerName={`message_blocks.${index}.start_button`}
            invalid={errors?.message_blocks?.[index]?.start_button?.message}
            block={block}
            isLoading={isLoading}
            requiredProps={{ i18nKey: 'journey-step.form.blocks.sequence.start_button.required', requiredText: 'Sequence button label is required.' }}
            placeholder={t('journey-step.form.blocks.sequence.start_button.placeholder')}
          />
        )}

        <Elevation
          key={block.id}
          style={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '4px',
            marginTop: '10px',
            padding: '15px',
            borderLeftColor: 'var(--mdc-theme-primary)',
            borderLeftWidth: '2px'
          }}
        >
          <BlockTitleActions index={index} handleRemoveBlock={handleRemoveBlock} handleMoveUpBlock={handleMoveUpBlock} handleMoveDownBlock={handleMoveDownBlock}>
            <label htmlFor={`message_blocks.${index}.step`}>
              <Trans i18nKey="journey-step.form.blocks.sequence.step.label">Step</Trans> {index + 1}
            </label>
          </BlockTitleActions>

          <Controller
            control={control}
            defaultValue={block.sequence_content_type || 'TITLE-AND-DESCRIPTION'}
            name={`message_blocks.${index}.sequence_content_type`}
            rules={{ required: t('journey-step.form.blocks.sequence.content_type.required', 'Content type is required.') as string }}
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <>
                <Select
                  enhanced
                  options={blockContentTypeOptions}
                  id={name}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  placeholder={t('journey-step.form.blocks.sequence.content_type.placeholder')}
                  icon="format_list_numbered"
                  invalid={errors?.message_blocks?.[index]?.sequence_content_type?.message ? true : false}
                  aria-invalid={errors?.message_blocks?.[index]?.sequence_content_type?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />

                <TextQuestion
                  control={control}
                  property="sequence_title"
                  labelProps={{
                    labelText: 'Title',
                    htmlFor: `message_blocks.${index}.sequence_title`,
                    i18nKey: 'journey-step.form.blocks.sequence.step.title.label'
                  }}
                  controllerName={`message_blocks.${index}.sequence_title`}
                  invalid={errors?.message_blocks?.[index]?.sequence_title?.message}
                  block={block}
                  isLoading={isLoading}
                  requiredProps={{ i18nKey: 'journey-step.form.blocks.sequence.step.title.required', requiredText: 'Title is required' }}
                  placeholder={t('journey-step.form.blocks.sequence.step.title.placeholder')}
                />

                <TextQuestion
                  control={control}
                  property="sequence_text"
                  labelProps={{
                    labelText: 'Content text',
                    htmlFor: `message_blocks.${index}.sequence_text`,
                    i18nKey: 'journey-step.form.blocks.sequence.step.text.label'
                  }}
                  controllerName={`message_blocks.${index}.sequence_text`}
                  invalid={errors?.message_blocks?.[index]?.sequence_text?.message}
                  block={block}
                  isLoading={isLoading}
                  requiredProps={{ i18nKey: 'journey-step.form.blocks.sequence.step.text.required', requiredText: 'Text is required' }}
                  placeholder={t('journey-step.form.blocks.sequence.step.text.placeholder')}
                />

                {value === SequenceContentTypes.Url && (
                  <TextQuestion
                    control={control}
                    property="sequence_content_url"
                    type="url"
                    labelProps={{
                      labelText: 'Content URL',
                      htmlFor: `message_blocks.${index}.sequence_content_url`,
                      i18nKey: 'journey-step.form.blocks.sequence.step.url.label'
                    }}
                    controllerName={`message_blocks.${index}.sequence_content_url`}
                    invalid={errors?.message_blocks?.[index]?.sequence_content_url?.message}
                    block={block}
                    isLoading={isLoading}
                    requiredProps={{ i18nKey: 'journey-step.form.blocks.sequence.step.url.required', requiredText: 'Url is required' }}
                    placeholder={t('journey-step.form.blocks.sequence.step.url.placeholder')}
                  />
                )}

                {value === SequenceContentTypes.PdfFile && (
                  <TextQuestion
                    control={control}
                    property="sequence_content_url"
                    type="url"
                    labelProps={{
                      labelText: 'PDF File URL',
                      htmlFor: `message_blocks.${index}.sequence_content_url`,
                      i18nKey: 'journey-step.form.blocks.sequence.step.pdf_file.label'
                    }}
                    controllerName={`message_blocks.${index}.sequence_content_url`}
                    invalid={errors?.message_blocks?.[index]?.sequence_content_url?.message}
                    block={block}
                    isLoading={isLoading}
                    requiredProps={{ i18nKey: 'journey-step.form.blocks.sequence.step.url.required', requiredText: 'Url is required' }}
                    placeholder={t('journey-step.form.blocks.sequence.step.pdf_file.placeholder')}
                  />
                )}

                {value === SequenceContentTypes.Video && (
                  <TextQuestion
                    control={control}
                    property="sequence_content_url"
                    type="url"
                    labelProps={{
                      labelText: 'Video Url',
                      htmlFor: `message_blocks.${index}.sequence_content_url`,
                      i18nKey: 'journey-step.form.blocks.sequence.step.video.label'
                    }}
                    controllerName={`message_blocks.${index}.sequence_content_url`}
                    invalid={errors?.message_blocks?.[index]?.sequence_content_url?.message}
                    block={block}
                    isLoading={isLoading}
                    requiredProps={{ i18nKey: 'journey-step.form.blocks.sequence.step.url.required', requiredText: 'Url is required' }}
                    placeholder={t('journey-step.form.blocks.sequence.step.video.placeholder')}
                  />
                )}

                {value === SequenceContentTypes.Image && (
                  <>
                    <TextQuestion
                      control={control}
                      property="sequence_content_url"
                      type="url"
                      labelProps={{
                        labelText: 'Image Url',
                        htmlFor: `message_blocks.${index}.sequence_content_url`,
                        i18nKey: 'journey-step.form.blocks.sequence.step.image.label'
                      }}
                      controllerName={`message_blocks.${index}.sequence_content_url`}
                      invalid={errors?.message_blocks?.[index]?.sequence_content_url?.message}
                      block={block}
                      isLoading={isLoading}
                      requiredProps={{ i18nKey: 'journey-step.form.blocks.sequence.step.url.required', requiredText: 'Url is required' }}
                      placeholder={t('journey-step.form.blocks.sequence.step.image.placeholder')}
                    />

                    <TextQuestion
                      control={control}
                      property="sequence_image_alt_text"
                      labelProps={{
                        labelText: 'Image Alt Text',
                        htmlFor: `message_blocks.${index}.sequence_image_alt_text`,
                        i18nKey: 'journey-step.form.blocks.sequence.step.image_alt_text.label'
                      }}
                      controllerName={`message_blocks.${index}.sequence_image_alt_text`}
                      invalid={errors?.message_blocks?.[index]?.sequence_image_alt_text?.message}
                      block={block}
                      isLoading={isLoading}
                      requiredProps={{ i18nKey: 'journey-step.form.blocks.sequence.step.image_alt_text.required', requiredText: 'Alt text is required' }}
                      placeholder={t('journey-step.form.blocks.sequence.step.image.placeholder')}
                    />
                  </>
                )}
              </>
            )}
          />
        </Elevation>
        {blocksLength === index + 1 && (
          <Button outlined onClick={handleAddSequence} style={{ width: '100%', margin: '10px 0' }}>
            <Trans i18nKey="journey-step.form.blocks.sequence.add-step">Add another step on the sequence</Trans>
          </Button>
        )}
      </div>
    </>
  );
}
