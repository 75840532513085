import axios, { AxiosInstance, AxiosResponse, AxiosPromise } from 'axios';
import { v4 } from 'uuid';
import { SearchFilter } from './SearchFilter';

abstract class HttpClient<T> {
  protected readonly instance: AxiosInstance;
  private readonly basePath: string;

  public constructor({ baseURL, basePath }: { baseURL: string; basePath: string }) {
    this.instance = axios.create({
      baseURL,
      timeout: 20000,
      headers: {
        'Content-type': 'application/json',
        'X-Correlation-Id': v4()
      }
    });
    this.basePath = basePath;

    this.initializeResponseInterceptor();
  }

  public async getAll(): Promise<AxiosResponse> {
    return this.instance.get<T[]>(this.buildUrlNoCache());
  }

  public async getAllSearch(searchFilter: SearchFilter): Promise<AxiosResponse> {
    const url = this.buildUrlWithNoCacheTimestamp(this.buildUrl());
    const params = this.buildSearchUrlParams(searchFilter);
    if (params) {
      const config = {
        params: params
      };
      return this.instance.get<T[]>(url, config);
    }

    return this.instance.get<T[]>(url);
  }

  public async getAllSearchPath(path: string, searchFilter: SearchFilter): Promise<AxiosResponse> {
    const url = this.buildUrlWithNoCacheTimestamp(this.buildUrl(path));
    const params = this.buildSearchUrlParams(searchFilter);
    if (params) {
      const config = {
        params: params
      };
      return this.instance.get<T[]>(url, config);
    }

    return this.instance.get<T[]>(url);
  }

  public async getById(id: string): Promise<AxiosResponse> {
    return this.instance.get<T>(this.buildUrlNoCache(id));
  }

  public async getPath(path: string): Promise<AxiosResponse> {
    return this.instance.get<T>(this.buildUrlNoCache(path));
  }

  public async getCachedById(id: string): Promise<AxiosResponse> {
    return this.instance.get<T>(this.buildUrl(id));
  }

  public async deleteById(id: string): Promise<AxiosResponse> {
    return this.instance.delete<T>(this.buildUrl(id));
  }

  public async post(body: unknown): Promise<AxiosResponse> {
    return this.instance.post(this.buildUrl(), body);
  }

  public async postPath(path: string, body: unknown): Promise<AxiosResponse> {
    return this.instance.post(this.buildUrl(path), body);
  }

  public async customPostUrl(url: string, body: unknown): Promise<AxiosResponse> {
    return this.instance.post(url, body);
  }

  public async put(id: string, body: unknown): Promise<AxiosResponse> {
    return this.instance.put(this.buildUrl(id), body);
  }

  public async putPath(path: string, body: unknown): Promise<AxiosResponse> {
    return this.instance.put(this.buildUrl(path), body);
  }

  private initializeResponseInterceptor(): void {
    this.instance.interceptors.response.use(this.handleResponse, this.handleError);
  }

  private handleResponse({ data }: AxiosResponse): AxiosResponse {
    return data;
  }

  private buildUrl(path?: string): string {
    return path ? `${this.basePath}/${path}` : this.basePath;
  }

  private buildSearchUrlParams(searchFilter: SearchFilter): SearchFilter | undefined {
    if (!searchFilter) {
      return undefined;
    }

    const params = {} as SearchFilter;
    if (searchFilter.query) {
      params.query = searchFilter.query.toLowerCase();
    }
    if (searchFilter.queryFieldName) {
      params.queryFieldName = searchFilter.queryFieldName;
    }
    if (searchFilter.limit) {
      params.limit = searchFilter.limit;
    }
    if (searchFilter.lastKey) {
      params.lastKey = searchFilter.lastKey;
    }
    return params;
  }

  private buildUrlNoCache(path?: string): string {
    return this.buildUrlWithNoCacheTimestamp(this.buildUrl(path));
  }

  private buildUrlWithNoCacheTimestamp(url: string): string {
    const noCacheTimestamp = new Date().getTime();
    return `${url}?timestamp=${noCacheTimestamp}`;
  }

  protected handleError(error: unknown): AxiosPromise {
    return Promise.reject(error);
  }
}

export default HttpClient;
