import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import JourneyService from './JourneyService';
import ErrorMessage from '../ErrorMessage';
import LoadingModal from '../LoadingModal';
import { Journey } from './JourneyRepository';
import Message from '../../services/Message';
import { useTranslation } from 'react-i18next';

function JourneyCreateCopy(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  const journeyService = new JourneyService();
  const { data, isFetching, isError, error } = journeyService.useCreateJourneyCopy(id);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  if (!data) {
    Message.error(t('journey.create-copy.error'));
    return <Redirect to="/journeys" />;
  }

  const newJourneyCopy = data as Journey;
  Message.success(t('journey.create-copy.success'));
  return <Redirect to={`/journeys/${newJourneyCopy.id}/edit`} />;
}

export default JourneyCreateCopy;
