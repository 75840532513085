import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useForm, useWatch, Controller, FieldValues, useFieldArray, FieldErrors } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { flushSync } from 'react-dom';

import Email from '../Settings/IntegrationSettings/Icons/email.png';
import Slack from '../Settings/IntegrationSettings/Icons/slack.png';
import Beedoo from '../Settings/IntegrationSettings/Icons/beedoo.png';
import Whatsapp from '../Settings/IntegrationSettings/Icons/whatsapp.png';
import Teams from '../Settings/IntegrationSettings/Icons/teams.png';

import authService from '../../services/AuthService';
import CompanyService from '../Settings/CompanySettings/CompanyService';

import { debounce } from '../common/libs/debounce';

// Remirror - ProseMirror editor
import { ImageExtension, SubExtension, SupExtension, NodeFormattingExtension, MentionAtomExtension, wysiwygPreset } from 'remirror/extensions';
import { createMarkPositioner, LinkExtension, ShortcutHandlerProps } from 'remirror/extensions';
import { useActive, useAttrs, useChainedCommands, useCurrentSelection, useExtensionEvent, useUpdateReason, useEmoji } from '@remirror/react';
import { TextColorExtension } from 'remirror/extensions';
import { EmojiExtension } from '@remirror/extension-emoji';
import { EmojiPopupComponent } from '@remirror/react-components';
import { EditorComponent, Remirror, ThemeProvider, useRemirror } from '@remirror/react';
import { ComponentItem, Toolbar, ToolbarItemUnion, FloatingToolbar, FloatingWrapper } from '@remirror/react';
import { MentionAtomPopupComponent } from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/emotion';
import { ProsemirrorDevTools } from '@remirror/dev';
import { RemirrorManager } from 'remirror';
import type { EditorState } from '@remirror/core-types';
import emojiData from 'svgmoji/emoji.json';
// import { i18n } from '@remirror/i18n';
// import es from '@remirror/i18n/es/messages';

import styles from './StepJourney.module.css';

import { GridRow, GridCell } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import '@rmwc/list/styles';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import { Radio } from '@rmwc/radio';
import '@rmwc/radio/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { IconButton } from '@rmwc/icon-button';
import '@rmwc/icon-button/styles';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Typography } from '@rmwc/typography';
import '@material/typography/dist/mdc.typography.css';

import { Select } from '@rmwc/select';
import '@rmwc/select/styles';

import { Elevation } from '@rmwc/elevation';
import '@rmwc/elevation/styles';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';

import { BadgeAnchor, Badge } from '@rmwc/badge';
import '@rmwc/badge/styles';

import '@rmwc/switch/styles';

import { Menu, MenuSurfaceAnchor, MenuItem, MenuOnSelectEventT } from '@rmwc/menu';
import '@rmwc/menu/styles';

import TooltipWrapper from '../common/TooltipWrapper/TooltipWrapper';
import StepJourneyMessagePreview from './StepJourneyMessagePreview';
import { ClearFormattingExtension } from '../common/ClearFormattingExtension/ClearFormattingExtension';
import { ParagraphExtension } from '../common/ParagraphExtension/ParagraphExtension';
import { HardBreakExtension } from '../common/HardBreakExtension/HardBreakExtension';
import { StepJourneyChannel, WhatsappTemplate } from './StepJourneyRepository';
import { JourneyFlowType } from './JourneyRepository';
import { dateFormat } from '../common/DatesFormat/dateTimeFormat';
import EditorPopup from './EditorPopup';
import { PortalLinkBlockType } from './MessageBlocksComponents/BlockTypes/PortalLinkBlockType';
import { CallToActionBlockType } from './MessageBlocksComponents/BlockTypes/CallToActionBlockType';
import { TaskBlockType } from './MessageBlocksComponents/BlockTypes/TaskBlockType';
import { SequenceBlockType } from './MessageBlocksComponents/BlockTypes/SequenceBlockType';
import { FormBlockType } from './MessageBlocksComponents/BlockTypes/FormBlockType';
import { SequenceContentTypes } from './MessageBlocksComponents/BlockTypes/SequenceBlockType';
import { MessageBlocksFormTypes } from './MessageBlocksComponents/BlockTypes/FormBlockType';
import { PollBlockType } from './MessageBlocksComponents/BlockTypes/PollBlockType';
import { MessageBlocksPollTypes } from './MessageBlocksComponents/BlockTypes/PollBlockType';
import BlocksPreviewPopup from './BlocksPreviewPopup';

export enum MessageBlocksTypes {
  CallToAction = 'CALL-TO-ACTION',
  Poll = 'POLL',
  Task = 'TASK',
  Form = 'FORM',
  Sequence = 'SEQUENCE',
  PortalLink = 'PORTAL-LINK'
}

const TopToolbar = ({ setIsEditingLink, onClickAddImageButton }: { setIsEditingLink: (value: boolean) => void; onClickAddImageButton: () => void }) => {
  const { t } = useTranslation();

  const toolbarItems: ToolbarItemUnion[] = [
    {
      type: ComponentItem.ToolbarGroup,
      label: t('journey-step.form.toolbar.heading-one', 'Heading Formatting'),
      items: [
        {
          type: ComponentItem.ToolbarCommandButton,
          commandName: 'toggleHeading',
          display: 'icon',
          attrs: { level: 1 }
        }
      ],
      separator: 'none'
    },
    {
      type: ComponentItem.ToolbarMenu,
      label: t('journey-step.form.toolbar.headingg-group', 'Headings'),
      icon: 'heading',
      items: [
        {
          type: ComponentItem.MenuGroup,
          role: 'radio',
          items: [
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleHeading',
              attrs: { level: 1 }
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleHeading',
              attrs: { level: 2 }
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleHeading',
              attrs: { level: 3 }
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleHeading',
              attrs: { level: 4 }
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleHeading',
              attrs: { level: 5 }
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleHeading',
              attrs: { level: 6 }
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleBlockquote'
            }
          ]
        }
      ]
    },
    {
      type: ComponentItem.ToolbarGroup,
      label: t('journey-step.form.toolbar.clipboard', 'Clipboard'),
      items: [
        // { type: ComponentItem.ToolbarCommandButton, commandName: 'copy', display: 'icon' },
        { type: ComponentItem.ToolbarCommandButton, commandName: 'paste', display: 'icon' },
        { type: ComponentItem.ToolbarCommandButton, commandName: 'clearFormatting', display: 'icon' }
      ],
      separator: 'both'
    },
    {
      type: ComponentItem.ToolbarGroup,
      label: t('journey-step.form.toolbar.simple-formatting', 'Simple Formatting'),
      items: [{ type: ComponentItem.ToolbarCommandButton, commandName: 'toggleBold', display: 'icon' }],
      separator: 'none'
    },
    {
      type: ComponentItem.ToolbarMenu,
      label: t('journey-step.form.toolbar.formatting', 'Formatting'),
      items: [
        {
          type: ComponentItem.MenuGroup,
          role: 'radio',
          items: [
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleBold'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleItalic'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleUnderline'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleSubscript'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleSuperscript'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleStrike'
            }
          ]
        }
      ]
    },
    {
      type: ComponentItem.ToolbarGroup,
      label: t('journey-step.form.toolbar.files', 'Files'),
      items: [
        {
          type: ComponentItem.ToolbarButton,
          onClick: (): void => {
            setIsEditingLink(true);
          },
          icon: 'link'
        },
        {
          type: ComponentItem.ToolbarButton,
          onClick: onClickAddImageButton,
          icon: 'imageAddLine'
        },
        {
          type: ComponentItem.ToolbarButton,
          onClick: (): void => alert(t('journey-step.form.toolbar.video', 'Copy and paste a link to an Youtube video')),
          icon: 'videoLine'
        },
        {
          type: ComponentItem.ToolbarButton,
          onClick: (): void => alert(t('journey-step.form.toolbar.attachment', 'Drag and drop a file inside the editor')),
          icon: 'attachment2'
        }
      ],
      separator: 'both'
    },
    {
      type: ComponentItem.ToolbarMenu,
      label: t('journey-step.form.toolbar.formatting-other', 'Other formatting'),
      items: [
        {
          type: ComponentItem.MenuGroup,
          role: 'radio',
          items: [
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleBulletList'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleOrderedList'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'toggleTaskList'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'undo'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'redo'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'increaseIndent'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'decreaseIndent'
            },
            {
              type: ComponentItem.MenuCommandPane,
              commandName: 'insertHardBreak'
            }
          ]
        }
      ]
    }
  ];

  return <Toolbar items={toolbarItems} refocusEditor label={t('journey-step.form.toolbar.label', 'Top Toolbar')} />;
};

export const fullWithStyle = {
  minWidth: '100%',
  backgroundColor: 'whitesmoke'
} as React.CSSProperties;

function useLinkShortcut() {
  const [linkShortcut, setLinkShortcut] = React.useState<ShortcutHandlerProps | undefined>();
  const [isEditingLink, setIsEditingLink] = React.useState(false);

  useExtensionEvent(
    LinkExtension,
    'onShortcut',
    React.useCallback(
      (props) => {
        if (!isEditingLink) {
          setIsEditingLink(true);
        }

        return setLinkShortcut(props);
      },
      [isEditingLink]
    )
  );

  return { linkShortcut, isEditingLink, setIsEditingLink };
}

function useFloatingLinkState({
  linkShortcut,
  isEditingLink,
  setIsEditingLink
}: {
  linkShortcut: ShortcutHandlerProps | undefined;
  isEditingLink: boolean;
  setIsEditingLink: (value: boolean) => void;
}) {
  const chain = useChainedCommands();
  const { to, empty } = useCurrentSelection();

  const url = (useAttrs().link()?.href as string) ?? '';
  const [href, setHref] = React.useState<string>(url);

  // A positioner which only shows for links.
  const linkPositioner = React.useMemo(() => createMarkPositioner({ type: 'link' }), []);

  const onRemove = React.useCallback(() => {
    return chain.removeLink().focus().run();
  }, [chain]);

  const updateReason = useUpdateReason();

  React.useLayoutEffect(() => {
    if (!isEditingLink) {
      return;
    }

    if (updateReason.doc || updateReason.selection) {
      setIsEditingLink(false);
    }
  }, [isEditingLink, setIsEditingLink, updateReason.doc, updateReason.selection]);

  React.useEffect(() => {
    setHref(url);
  }, [url]);

  const submitHref = React.useCallback(() => {
    setIsEditingLink(false);
    const range = linkShortcut ?? undefined;

    if (href === '') {
      chain.removeLink();
    } else {
      chain.updateLink({ href, auto: false }, range);
    }

    chain.focus(range?.to ?? to).run();
  }, [setIsEditingLink, linkShortcut, chain, href, to]);

  const cancelHref = React.useCallback(() => {
    setIsEditingLink(false);
  }, [setIsEditingLink]);

  const clickEdit = React.useCallback(() => {
    if (empty) {
      chain.selectLink();
    }

    setIsEditingLink(true);
  }, [chain, empty, setIsEditingLink]);

  return React.useMemo(
    () => ({
      href,
      setHref,
      linkShortcut,
      linkPositioner,
      isEditingLink,
      clickEdit,
      onRemove,
      submitHref,
      cancelHref
    }),
    [href, linkShortcut, linkPositioner, isEditingLink, clickEdit, onRemove, submitHref, cancelHref]
  );
}

const DelayAutoFocusInput = ({
  autoFocus,
  onChange,
  onKeyPress,
  onClickConfirm,
  value,
  placeholder
}: {
  autoFocus: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClickConfirm: () => void;
  value: string;
  placeholder: string;
}) => {
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!autoFocus) {
      return;
    }

    const frame = window.requestAnimationFrame(() => {
      inputRef.current?.focus();
    });

    return () => {
      window.cancelAnimationFrame(frame);
    };
  }, [autoFocus]);

  return (
    <TextField
      type="url"
      outlined
      inputRef={inputRef}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyPress}
      style={{ zIndex: 20, minWidth: '300px', backgroundColor: '#fff' }}
      trailingIcon={{
        icon: 'done',
        tabIndex: 0,
        label: t('journey-step.editor.confirm-icon', 'Confirm'),
        onClick: onClickConfirm
      }}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ImageToolbar = ({ isEditingImage, setIsEditingImage, manager }: { isEditingImage: boolean; setIsEditingImage: (v: boolean) => void; manager: RemirrorManager<any> }) => {
  const { t } = useTranslation();

  const [imageSrc, setImageSrc] = React.useState('');

  const insertImageSrc = () => {
    manager.store.commands.insertImage({ src: imageSrc });
    setIsEditingImage(false);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageSrc(event.target.value);
  };
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    if (key === 'Enter') {
      insertImageSrc();
    }
    if (key === 'Escape') {
      setIsEditingImage(false);
      setImageSrc('');
    }
  };
  const onClickConfirm = () => {
    insertImageSrc();
  };

  return (
    <>
      <FloatingWrapper positioner="always" placement="bottom-end" enabled={isEditingImage} renderOutsideEditor>
        <DelayAutoFocusInput
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
          value={imageSrc}
          placeholder={t('journey-step.editor.image.placeholder', 'Image link...')}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onClickConfirm={onClickConfirm}
        />
      </FloatingWrapper>
    </>
  );
};

const FloatingLinkToolbar = ({
  linkShortcut,
  isEditingLink,
  setIsEditingLink
}: {
  linkShortcut: ShortcutHandlerProps | undefined;
  isEditingLink: boolean;
  setIsEditingLink: (value: boolean) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const { linkPositioner, clickEdit, onRemove, submitHref, href, setHref, cancelHref } = useFloatingLinkState({ linkShortcut, isEditingLink, setIsEditingLink });
  const active = useActive();
  const activeLink = active.link();
  const { empty } = useCurrentSelection();
  const linkEditItems: ToolbarItemUnion[] = React.useMemo(
    () => [
      {
        type: ComponentItem.ToolbarGroup,
        label: 'Link',
        items: activeLink
          ? [
              { type: ComponentItem.ToolbarButton, onClick: () => clickEdit(), icon: 'pencilLine', label: t('journey-step.editor.link.edit', 'Edit ') },
              { type: ComponentItem.ToolbarButton, onClick: onRemove, icon: 'linkUnlink', label: t('journey-step.editor.link.remove', 'Remove ') }
            ]
          : []
      }
    ],
    [clickEdit, onRemove, activeLink]
  );

  const items: ToolbarItemUnion[] = React.useMemo(() => linkEditItems, [linkEditItems]);

  return (
    <>
      <FloatingToolbar items={items} positioner="selection" placement="top" enabled={!isEditingLink} />
      <FloatingToolbar items={linkEditItems} positioner={linkPositioner} placement="bottom" enabled={!isEditingLink && empty} />

      <FloatingWrapper positioner="always" placement="bottom-end" enabled={isEditingLink} renderOutsideEditor>
        <DelayAutoFocusInput
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
          value={href}
          placeholder={t('journey-step.editor.link.placeholder', 'Enter link...')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setHref(event.target.value)}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
            const { key } = event;
            if (key === 'Enter') {
              submitHref();
            }
            if (key === 'Escape') {
              cancelHref();
            }
          }}
          onClickConfirm={() => submitHref()}
        />
      </FloatingWrapper>
    </>
  );
};

function getDefaultPeriodValue(flowtype: JourneyFlowType): string {
  if (flowtype === JourneyFlowType.Communication) {
    return 'fixed-date';
  } else if (flowtype === JourneyFlowType.Onboarding) {
    return 'before-first-day';
  } else {
    return 'after-last-day';
  }
}

function StepJourneyForm({ journeyId, journey, stepId, onFormSubmit, onCancel, defaultValues, isLoading }: InferProps<typeof StepJourneyForm.propTypes>): JSX.Element {
  const { t } = useTranslation();

  if (defaultValues && !defaultValues.message_blocks) {
    defaultValues.message_blocks = [];
  }

  if (defaultValues && defaultValues.message_source) {
    // Clear font family from legacy (can remove later)
    defaultValues.message_source = defaultValues.message_source.replace(new RegExp(/{"type":"fontFamily","attrs":{"fontFamily":"[^\r\n\t\f\v"]*"}},?/, 'ig'), '');
  }

  const { control, setValue, handleSubmit, watch, errors } = useForm({ defaultValues });
  const { fields: blockFields, append: appendBlock, remove: removeBlock, move: moveBlock } = useFieldArray({ name: 'message_blocks', control });

  const isCommunicationType = journey.flow_type === JourneyFlowType.Communication ? true : false;
  const isOffboardingType = journey.flow_type === JourneyFlowType.Offboarding ? true : false;
  const isOnboardingType = journey.flow_type === JourneyFlowType.Onboarding ? true : false;

  const defaultPeriodValue = getDefaultPeriodValue(journey.flow_type);

  const periodValue: string = useWatch({ control, name: 'period', defaultValue: defaultValues?.period ? defaultValues?.period : defaultPeriodValue });
  const periodTimeValue: string | undefined = useWatch({ control, name: 'period_time' });
  const periodDaysValue: string | undefined = useWatch({ control, name: 'period_days' });
  const periodDateValue: string | undefined = useWatch({ control, name: 'period_date' });
  const channelValue: string | undefined = useWatch({ control, name: 'channel', defaultValue: defaultValues?.channel ?? StepJourneyChannel.Email });
  const messageToWhom: string | undefined = useWatch({ control, name: 'to_whom' });
  const messageToWhomCopy: string | undefined = useWatch({ control, name: 'to_whom_copy' });
  const messageToWhomReply: string | undefined = useWatch({ control, name: 'to_whom_reply' });
  const messageSubject: string | undefined = useWatch({ control, name: 'subject' });
  const messageWhatsappTemplate: string | undefined = useWatch({ control, name: 'whatsapp_template' });

  const [messageHtml, setMessageHtml] = React.useState('');
  const [messageJson, setMessageJson] = React.useState('');

  const [showReplyTo, setShowReplyTo] = React.useState(defaultValues?.to_whom_reply ? true : false);
  const [showCopyTo, setShowCopyTo] = React.useState(defaultValues?.to_whom_copy ? true : false);

  const messageBlocksWatch = watch('message_blocks', blockFields);

  const messageBlocks = organizeMessageBlocks(messageBlocksWatch);

  if (periodValue === 'first-day' && periodDaysValue !== '') {
    setValue('period_days', '', { shouldValidate: true });
  }

  // TODO: Review editor toolbar and create custom:
  // Here some exemples:
  // https://github.com/duyha1309/demo-clear-bulletlist-orderrest-list/tree/f704866263d5924133f071085425e33a88ba9dad/src/Tools
  // https://github.com/acimack/hypersheet/blob/4858c9cc8dd4c8644707807c34e86bed5ff92315/client/src/components/NodeView/NodeContent/TextEditor/TextEditorTools/TextEditorTools.tsx
  // https://github.com/Team-ICON/LiveMemo-frontend/blob/7bd68634ff9421551db934aab9d28212ab0ff79c/live-memo-client/src/components/CreateMemo/ExtensionButtons.js
  const editorExtensions = [
    new ParagraphExtension(),
    new HardBreakExtension(),
    new ImageExtension({ enableResizing: true }),
    new SubExtension(),
    new SupExtension(),
    new NodeFormattingExtension({}),
    new MentionAtomExtension({ matchers: [{ name: 'at', char: '@' }], appendText: '' }),
    new LinkExtension({ defaultTarget: '_blank' }),
    new ClearFormattingExtension(),
    new TextColorExtension({}),
    new EmojiExtension({
      data: emojiData,
      moji: 'noto',
      fallback: ':-)',
      suggestionCharacter: ':',
      plainText: true
    }),
    ...wysiwygPreset()
  ];
  const { state: messageEditorState, manager } = useRemirror({
    extensions: () => editorExtensions,
    content: defaultValues?.message_source ? JSON.parse(defaultValues?.message_source) : '<p></p>', // Set the initial content.
    selection: 'start', // Place the cursor at the start of the document.
    stringHandler: 'html' // `markdown` is also available when the `MarkdownExtension` is added to the editor.
  });

  const onSubmit = async (values: FieldValues): Promise<void> => {
    values['message_html'] = messageHtml;
    values['message_source'] = messageJson;
    values['message_blocks'] = messageBlocks;
    onFormSubmit(values);
  };

  const delay = 2000;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeMessageEditor = debounce((event: { state: EditorState; helpers: any }): void => {
    setMessageHtml(event.helpers.getHTML());
    setMessageJson(JSON.stringify(event.helpers.getJSON()));
  }, delay);

  const whatsappTemplateOptions = [
    {
      label: t('journey-step.form.whatsapp_template.welcome_first', 'Welcome first message'),
      value: WhatsappTemplate.WelcomeFirstMessage
    },
    {
      label: t('journey-step.form.whatsapp_template.welcome_second', 'Welcome second message'),
      value: WhatsappTemplate.WelcomeSecondMessage
    },
    {
      label: t('journey-step.form.whatsapp_template.notification_update', 'Dynamic notification update messages'),
      value: WhatsappTemplate.UpdateNotificationMessage
    },
    {
      label: t('journey-step.form.whatsapp_template.notification_task', 'Dynamic notification tasks'),
      value: WhatsappTemplate.TaskNotificationMessage
    },
    {
      label: t('journey-step.form.whatsapp_template.notification_generic', 'Dynamic notification messages'),
      value: WhatsappTemplate.GenericNotificationMessage
    }
  ];

  const blockDefaultFormSubmit = (event: { preventDefault: () => void }): boolean => {
    event.preventDefault();
    return false;
  };

  const handleAddButtonLink = (): void => {
    appendBlock({
      type: MessageBlocksTypes.CallToAction,
      start_button: '',
      link: ''
    });
  };

  const handleAddTaskList = (): void => {
    appendBlock({
      type: MessageBlocksTypes.Task,
      start_button: '',
      question: ''
    });
  };

  const handleAddPoll = (): void => {
    appendBlock({
      type: MessageBlocksTypes.Poll,
      start_button: '',
      poll_type: MessageBlocksPollTypes.Text,
      question: '',
      options: [],
      ask_comment: false
    });
  };

  const handleAddForm = (): void => {
    appendBlock({
      type: MessageBlocksTypes.Form,
      start_button: '',
      field_type: MessageBlocksFormTypes.Text,
      question: '',
      description: ''
    });
  };

  const handleAddSequence = (): void => {
    appendBlock({
      type: MessageBlocksTypes.Sequence,
      start_button: '',
      sequence_title: '',
      sequence_text: '',
      sequence_content_type: SequenceContentTypes.Text,
      sequence_content_url: '',
      sequence_image_alt_text: ''
    });
  };

  const handleAddPortal = (): void => {
    appendBlock({
      type: MessageBlocksTypes.PortalLink,
      start_button: ''
    });
  };

  const handleRemoveBlock = (index: number, name: string): void => {
    if (window.confirm(t('delete.confirm', { name: name }))) {
      flushSync(() => removeBlock(index));
    }
  };

  const handleMoveUpBlock = (index: number) => {
    if (index > 0) {
      flushSync(() => moveBlock(index, index - 1));
    }
  };

  const handleMoveDownBlock = (index: number) => {
    if (index >= 0) {
      flushSync(() => moveBlock(index, index + 1));
    }
  };

  const emailMessage = channelValue === StepJourneyChannel.Email;
  const whatsappMessage = channelValue === StepJourneyChannel.Whatsapp;
  const emailOnlyBlockField = !emailMessage;
  const subjectBlockField = !emailMessage && !whatsappMessage;
  const blockFieldsLength = blockFields?.length || 0;
  const disableAddBlocks = blockFieldsLength > 0;

  const toWhomOptions = [
    {
      label: t('mentions.entity.new_hire', '@NewHire'),
      value: 'new_hire'
    },
    {
      label: t('mentions.entity.leader', '@Leader'),
      value: 'leader'
    },
    {
      label: t('mentions.entity.buddy', '@Buddy'),
      value: 'buddy'
    }
  ];
  if (emailMessage) {
    toWhomOptions.splice(
      1,
      2,
      {
        label: t('mentions.entity.new_hire_personal_email', '@NewHire.PersonalEmail'),
        value: 'new_hire_personal_email'
      },
      {
        label: t('mentions.entity.new_hire_work_email', '@NewHire.WorkEmail'),
        value: 'new_hire_work_email'
      },
      {
        label: t('mentions.entity.leader_work_email', '@Leader.WorkEmail'),
        value: 'leader'
      },
      {
        label: t('mentions.entity.buddy_work_email', '@Buddy.WorkEmail'),
        value: 'buddy'
      }
    );
    toWhomOptions.push({
      label: t('mentions.entity.fixed_email', 'Email fixo'),
      value: 'fixed_email'
    });
  }

  const [showValidatorPopup, setIsPopupShown] = useState(false);
  const [showBlocksPreview, setIsFullPreviewShown] = useState(false);

  const handleOpenPreviewPopup = () => {
    setIsFullPreviewShown(!showBlocksPreview);
  };

  return (
    <form onSubmit={blockDefaultFormSubmit}>
      <GridRow>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue=""
            name="name"
            rules={{ required: t('journey-step.form.name.required', 'Name is required') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="journey-step.form.name.label">Step Name</Trans>
                  </label>
                </Typography>
                <TextField
                  type="text"
                  id={name}
                  name={name}
                  placeholder={t('journey-step.form.name.placeholder')}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.name?.message ? true : false}
                  aria-invalid={errors?.name?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                  helpText={{
                    persistent: true,
                    validationMsg: true,
                    children: t('journey-step.form.name.help', 'Name it to help identify this step in the journey')
                  }}
                />
              </>
            )}
          />
          {errors?.name?.message && <div role="alert">{errors?.name?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <Controller
            control={control}
            defaultValue={defaultPeriodValue}
            name="period"
            rules={{ required: t('journey-step.form.period.required', 'Period is required') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                {!isCommunicationType && (
                  <>
                    <Typography use="body1" tag="p">
                      <label htmlFor={name}>
                        <Trans i18nKey="journey-step.form.period.label">Selecione qual período da etapa:</Trans>
                      </label>
                    </Typography>
                  </>
                )}
                {isOffboardingType && (
                  <>
                    <Radio
                      name={name}
                      label={t('journey-step.form.period.before-last-day.label')}
                      value="before-last-day"
                      checked={value === 'before-last-day'}
                      onBlur={onBlur}
                      onChange={onChange}
                      inputRef={ref}
                      aria-invalid={errors?.period?.message ? true : false}
                      disabled={!!isLoading}
                    />
                    <Radio
                      name={name}
                      label={t('journey-step.form.period.last-day.label')}
                      value="last-day"
                      checked={value === 'last-day'}
                      onBlur={onBlur}
                      onChange={onChange}
                      inputRef={ref}
                      aria-invalid={errors?.period?.message ? true : false}
                      disabled={!!isLoading}
                    />
                    <Radio
                      name={name}
                      label={t('journey-step.form.period.after-last-day.label')}
                      value="after-last-day"
                      checked={value === 'after-last-day'}
                      onBlur={onBlur}
                      onChange={onChange}
                      inputRef={ref}
                      aria-invalid={errors?.period?.message ? true : false}
                      disabled={!!isLoading}
                    />
                  </>
                )}
                {isOnboardingType && (
                  <>
                    <Radio
                      name={name}
                      label={t('journey-step.form.period.before-first-day.label')}
                      value="before-first-day"
                      checked={value === 'before-first-day'}
                      onBlur={onBlur}
                      onChange={onChange}
                      inputRef={ref}
                      aria-invalid={errors?.period?.message ? true : false}
                      disabled={!!isLoading}
                    />
                    <Radio
                      name={name}
                      label={t('journey-step.form.period.first-day.label')}
                      value="first-day"
                      checked={value === 'first-day'}
                      onBlur={onBlur}
                      onChange={onChange}
                      inputRef={ref}
                      aria-invalid={errors?.period?.message ? true : false}
                      disabled={!!isLoading}
                    />
                    <Radio
                      name={name}
                      label={t('journey-step.form.period.after-first-day.label')}
                      value="after-first-day"
                      checked={value === 'after-first-day'}
                      onBlur={onBlur}
                      onChange={onChange}
                      inputRef={ref}
                      aria-invalid={errors?.period?.message ? true : false}
                      disabled={!!isLoading}
                    />
                  </>
                )}
              </>
            )}
          />
          {errors?.period?.message && <div role="alert">{errors?.period?.message}</div>}
        </GridCell>
        {isCommunicationType ? (
          <GridCell span={3}>
            <Controller
              control={control}
              defaultValue=""
              name="period_date"
              rules={{ required: t('journey-step.form.period_date.required', 'Date is required') as string }}
              render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
                <>
                  <Typography use="body1" tag="p">
                    <>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                    <label htmlFor={name}>
                      <StepJourneyFormPeriodDaysLabel period={periodValue} />
                    </label>
                  </Typography>
                  <TextField
                    type="date"
                    id={name}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    invalid={errors?.period_date?.message ? true : false}
                    aria-invalid={errors?.period_date?.message ? true : false}
                    disabled={!!isLoading}
                    style={fullWithStyle}
                  />
                </>
              )}
            />
            {errors?.period_days?.message && <div role="alert">{errors?.period_days?.message}</div>}
          </GridCell>
        ) : (
          <GridCell span={3}>
            <Controller
              control={control}
              defaultValue=""
              name="period_days"
              rules={periodValue === 'first-day' || periodValue === 'last-day' ? {} : { required: t('journey-step.form.period_days.required', 'Days is required') as string }}
              render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
                <>
                  <Typography use="body1" tag="p">
                    <>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                    <label htmlFor={name}>
                      <StepJourneyFormPeriodDaysLabel period={periodValue} />
                    </label>
                  </Typography>
                  <TextField
                    type="number"
                    id={name}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    invalid={errors?.period_days?.message ? true : false}
                    aria-invalid={errors?.period_days?.message ? true : false}
                    disabled={!!isLoading || periodValue === 'first-day' || periodValue === 'last-day'}
                    style={fullWithStyle}
                  />
                </>
              )}
            />
            {errors?.period_days?.message && <div role="alert">{errors?.period_days?.message}</div>}
          </GridCell>
        )}
        <GridCell span={9}>
          <Controller
            control={control}
            defaultValue=""
            name="period_time"
            rules={{ required: t('journey-step.form.period_time.required', 'Time is required') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <>
                <Typography use="body1" tag="p">
                  <label htmlFor={name}>
                    <Trans i18nKey="journey-step.form.period_time.label">Horário:</Trans>
                  </label>
                </Typography>
                <TextField
                  type="time"
                  id={name}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  invalid={errors?.period_time?.message ? true : false}
                  aria-invalid={errors?.period_time?.message ? true : false}
                  disabled={!!isLoading}
                />
              </>
            )}
          />
          {errors?.period_time?.message && <div role="alert">{errors?.period_time?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <div>
            <StepJourneyFormPeriodDaysSummary periodValue={periodValue} periodDaysValue={periodDaysValue} periodDateValue={periodDateValue} periodTimeValue={periodTimeValue} />
          </div>
        </GridCell>
        <GridCell span={12} className={styles.radioImages}>
          <Controller
            control={control}
            defaultValue="EMAIL"
            name="channel"
            rules={{ required: t('journey-step.form.channel.required', 'Communication channel is required') as string }}
            render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
              <StepCommunicationChannelsOptions name={name} value={value} onBlur={onBlur} onChange={onChange} ref={ref} errors={errors} isLoading={isLoading} />
            )}
          />
          {errors?.channel?.message && <div role="alert">{errors?.channel?.message}</div>}
        </GridCell>
        <GridCell span={12}>
          <GridRow>
            <GridCell span={12}>
              <GridRow>
                <GridCell span={12}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="to_whom"
                    rules={{ required: t('journey-step.form.to_whom.required', 'To whom is required') as string }}
                    render={({ onChange, onBlur, value, name }): React.ReactElement => (
                      <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography use="body1" tag="p">
                            <label htmlFor={name}>
                              <Trans i18nKey="journey-step.form.to_whom.label">To whom</Trans>
                            </label>
                          </Typography>
                          <div style={{ margin: '0 0 0 auto' }}>
                            <WrapLockedEmailTooltip locked={!!isLoading || emailOnlyBlockField}>
                              <Button trailingIcon="alternate_email" disabled={!!isLoading || emailOnlyBlockField} style={{ fontSize: '80%' }} onClick={() => setShowCopyTo(true)}>
                                <Trans i18nKey="journey-step.form.to_whom_copy.add-button">Add copy to</Trans>
                              </Button>
                            </WrapLockedEmailTooltip>
                            <WrapLockedEmailTooltip locked={!!isLoading || emailOnlyBlockField}>
                              <Button trailingIcon="reply" disabled={!!isLoading || emailOnlyBlockField} style={{ fontSize: '80%' }} onClick={() => setShowReplyTo(true)}>
                                <Trans i18nKey="journey-step.form.to_whom_reply.add-button">Add reply to</Trans>
                              </Button>
                            </WrapLockedEmailTooltip>
                          </div>
                        </div>
                        <Select
                          enhanced
                          id={name}
                          name={name}
                          options={toWhomOptions}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder={t('journey-step.form.to_whom.placeholder')}
                          invalid={errors?.to_whom?.message ? true : false}
                          aria-invalid={errors?.to_whom?.message ? true : false}
                          disabled={!!isLoading}
                          style={fullWithStyle}
                        />
                      </>
                    )}
                  />
                  {errors?.to_whom?.message && <div role="alert">{errors?.to_whom?.message}</div>}
                </GridCell>
                {messageToWhom === 'fixed_email' ? (
                  <GridCell span={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="to_whom_fixed_email"
                      rules={messageToWhom === 'fixed_email' ? { required: t('journey-step.form.to_whom_fixed_email.required', 'Fixed email is required') as string } : {}}
                      render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
                        <InputFormField
                          inputName={name}
                          inputLocked={emailOnlyBlockField}
                          inputLabel={<Trans i18nKey="journey-step.form.to_whom_fixed_email.label">Fixed email</Trans>}
                        >
                          <TextField
                            type="text"
                            id={name}
                            name={name}
                            placeholder={t('journey-step.form.to_whom_fixed_email.placeholder')}
                            helpText={{
                              persistent: true,
                              validationMsg: true,
                              children: t('journey-step.form.to_whom_fixed_email.help_text')
                            }}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            inputRef={ref}
                            invalid={errors?.to_whom_fixed_email?.message ? true : false}
                            aria-invalid={errors?.to_whom_fixed_email?.message ? true : false}
                            disabled={!!isLoading || emailOnlyBlockField}
                            style={fullWithStyle}
                          />
                        </InputFormField>
                      )}
                    />
                    {errors?.to_whom_fixed_email?.message && <div role="alert">{errors?.to_whom_fixed_email?.message}</div>}
                  </GridCell>
                ) : null}
                <GridCell span={12} style={showCopyTo ? {} : { display: 'none' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="to_whom_copy"
                    render={({ onChange, onBlur, value, name }): React.ReactElement => (
                      <InputFormField
                        inputName={name}
                        inputLocked={emailOnlyBlockField}
                        inputLabel={<Trans i18nKey="journey-step.form.to_whom_copy.label">Add in copy (optional)</Trans>}
                      >
                        <Select
                          enhanced
                          id={name}
                          name={name}
                          options={toWhomOptions}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder={t('journey-step.form.to_whom_copy.placeholder')}
                          invalid={errors?.to_whom_copy?.message ? true : false}
                          aria-invalid={errors?.to_whom_copy?.message ? true : false}
                          disabled={!!isLoading || emailOnlyBlockField}
                          style={fullWithStyle}
                        />
                      </InputFormField>
                    )}
                  />
                  {errors?.to_whom_copy?.message && <div role="alert">{errors?.to_whom_copy?.message}</div>}
                </GridCell>
                {messageToWhomCopy === 'fixed_email' ? (
                  <GridCell span={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="to_whom_copy_fixed_email"
                      rules={messageToWhomCopy === 'fixed_email' ? { required: t('journey-step.form.to_whom_fixed_email.required', 'Fixed email is required') as string } : {}}
                      render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
                        <InputFormField
                          inputName={name}
                          inputLocked={emailOnlyBlockField}
                          inputLabel={<Trans i18nKey="journey-step.form.to_whom_copy.fixed_email">Email to send copy</Trans>}
                        >
                          <TextField
                            type="text"
                            id={name}
                            name={name}
                            placeholder={t('journey-step.form.to_whom_fixed_email.placeholder')}
                            helpText={{
                              persistent: true,
                              validationMsg: true,
                              children: t('journey-step.form.to_whom_fixed_email.help_text')
                            }}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            inputRef={ref}
                            invalid={errors?.to_whom_fixed_email?.message ? true : false}
                            aria-invalid={errors?.to_whom_fixed_email?.message ? true : false}
                            disabled={!!isLoading || emailOnlyBlockField}
                            style={fullWithStyle}
                          />
                        </InputFormField>
                      )}
                    />
                    {errors?.to_whom_fixed_email?.message && <div role="alert">{errors?.to_whom_fixed_email?.message}</div>}
                  </GridCell>
                ) : null}
                <GridCell span={12} style={showReplyTo ? {} : { display: 'none' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="to_whom_reply"
                    render={({ onChange, onBlur, value, name }): React.ReactElement => (
                      <InputFormField
                        inputName={name}
                        inputLocked={emailOnlyBlockField}
                        inputLabel={<Trans i18nKey="journey-step.form.to_whom_reply.label">Add reply to email (optional)</Trans>}
                      >
                        <Select
                          enhanced
                          id={name}
                          name={name}
                          options={toWhomOptions}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder={t('journey-step.form.to_whom_reply.placeholder')}
                          invalid={errors?.to_whom_reply?.message ? true : false}
                          aria-invalid={errors?.to_whom_reply?.message ? true : false}
                          disabled={!!isLoading || emailOnlyBlockField}
                          style={fullWithStyle}
                        />
                      </InputFormField>
                    )}
                  />
                  {errors?.to_whom_reply?.message && <div role="alert">{errors?.to_whom_reply?.message}</div>}
                </GridCell>
                {messageToWhomReply === 'fixed_email' ? (
                  <GridCell span={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="to_whom_reply_fixed_email"
                      rules={messageToWhomReply === 'fixed_email' ? { required: t('journey-step.form.to_whom_fixed_email.required', 'Fixed email is required') as string } : {}}
                      render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
                        <InputFormField
                          inputName={name}
                          inputLocked={emailOnlyBlockField}
                          inputLabel={<Trans i18nKey="journey-step.form.to_whom_reply.fixed_email">Email to send as reply</Trans>}
                        >
                          <TextField
                            type="text"
                            id={name}
                            name={name}
                            placeholder={t('journey-step.form.to_whom_fixed_email.placeholder')}
                            helpText={{
                              persistent: true,
                              validationMsg: true,
                              children: t('journey-step.form.to_whom_fixed_email.help_text')
                            }}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            inputRef={ref}
                            invalid={errors?.to_whom_fixed_email?.message ? true : false}
                            aria-invalid={errors?.to_whom_fixed_email?.message ? true : false}
                            disabled={!!isLoading || emailOnlyBlockField}
                            style={fullWithStyle}
                          />
                        </InputFormField>
                      )}
                    />
                    {errors?.to_whom_fixed_email?.message && <div role="alert">{errors?.to_whom_fixed_email?.message}</div>}
                  </GridCell>
                ) : null}
                {whatsappMessage ? (
                  <GridCell span={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="whatsapp_template"
                      render={({ onChange, onBlur, value, name }): React.ReactElement => (
                        <InputFormField
                          inputName={name}
                          inputLocked={!whatsappMessage}
                          inputLabel={<Trans i18nKey="journey-step.form.whatsapp_template.label">Whatsapp Template</Trans>}
                        >
                          <Select
                            enhanced
                            id={name}
                            name={name}
                            options={whatsappTemplateOptions}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            placeholder={t('journey-step.form.whatsapp_template.placeholder')}
                            invalid={errors?.whatsapp_template?.message ? true : false}
                            aria-invalid={errors?.whatsapp_template?.message ? true : false}
                            disabled={!!isLoading}
                            style={fullWithStyle}
                          />
                        </InputFormField>
                      )}
                    />
                    {errors?.whatsapp_template?.message && <div role="alert">{errors?.whatsapp_template?.message}</div>}
                  </GridCell>
                ) : null}
                <GridCell span={12}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="subject"
                    rules={emailMessage ? { required: t('journey-step.form.subject.required', 'Subject is required') as string } : {}}
                    render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
                      <>
                        <WrapLockedEmailTooltip locked={subjectBlockField}>
                          <SubjectDynamicFieldButton
                            disabled={subjectBlockField}
                            onSelectDynamicField={(key: string) => {
                              setValue('subject', value + ' ' + key, { shouldValidate: true });
                            }}
                          />
                        </WrapLockedEmailTooltip>
                        <InputFormField inputName={name} inputLocked={subjectBlockField} inputLabel={<Trans i18nKey="journey-step.form.subject.label">Subject</Trans>}>
                          <TextField
                            type="text"
                            id={name}
                            name={name}
                            placeholder={t('journey-step.form.subject.placeholder')}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            inputRef={ref}
                            invalid={errors?.subject?.message ? true : false}
                            aria-invalid={errors?.subject?.message ? true : false}
                            disabled={!!isLoading || subjectBlockField}
                            style={fullWithStyle}
                          />
                        </InputFormField>
                      </>
                    )}
                  />
                  {errors?.subject?.message && <div role="alert">{errors?.subject?.message}</div>}
                </GridCell>
                <GridCell desktop={6} tablet={12} className={styles.textEditorWrapper}>
                  <GridRow style={{ height: '100%' }}>
                    <GridCell span={12}>
                      <AllStyledComponent>
                        <ThemeProvider>
                          <Remirror manager={manager} onChange={onChangeMessageEditor} initialContent={messageEditorState}>
                            <RemirrorToolbarAndBody manager={manager} />
                          </Remirror>
                        </ThemeProvider>
                      </AllStyledComponent>
                    </GridCell>
                    <GridCell span={12} style={{ textAlign: 'right', marginTop: '-20px' }}>
                      <Button onClick={() => setIsPopupShown(true)}>
                        <Trans i18nKey="journey-step.form.blocks.validate-text-button">Validate text</Trans>
                      </Button>
                    </GridCell>
                    {showValidatorPopup && <EditorPopup messageHtml={messageHtml} messageChannel={channelValue} onClose={(): void => setIsPopupShown(false)} />}
                    {showBlocksPreview && (
                      <ThemeProvider>
                        <BlocksPreviewPopup
                          journeyId={journeyId}
                          stepId={stepId || ''}
                          messageChannel={channelValue}
                          messageToWhom={messageToWhom}
                          messageToWhomCopy={messageToWhomCopy}
                          messageSubject={messageSubject}
                          messageHtml={messageHtml}
                          messageBlocks={messageBlocks}
                          whatsappTemplate={messageWhatsappTemplate}
                          onClose={handleOpenPreviewPopup}
                        />
                      </ThemeProvider>
                    )}
                  </GridRow>
                </GridCell>
                <GridCell desktop={6} tablet={12}>
                  <GridRow style={{ height: '100%' }}>
                    <StepJourneyMessagePreview
                      loadFromFormEdit={true}
                      journeyId={journeyId}
                      stepId={stepId}
                      messageChannel={channelValue}
                      messageToWhom={messageToWhom}
                      messageToWhomCopy={messageToWhomCopy}
                      messageSubject={messageSubject}
                      messageHtml={messageHtml}
                      messageBlocks={[]}
                    />
                  </GridRow>
                </GridCell>
                <GridCell desktop={12} tablet={12} className={styles.textEditorWrapper}>
                  <GridRow style={{ height: '100%' }}>
                    <GridCell span={12}>
                      <Typography use="body2" tag="p" style={{ marginLeft: '2px' }}>
                        <Trans i18nKey="journey-step.blocks.add-text">Add:</Trans>
                      </Typography>
                      <div>
                        <WrapLockedAddBlocksTooltip locked={disableAddBlocks}>
                          <Button disabled={disableAddBlocks} icon="add_link" outlined style={{ marginRight: '10px', marginTop: '5px' }} onClick={handleAddButtonLink}>
                            <Trans i18nKey="journey-step.blocks.call-to-action.add-button">Call to action</Trans>
                          </Button>
                        </WrapLockedAddBlocksTooltip>
                        <WrapLockedAddBlocksTooltip locked={disableAddBlocks}>
                          <Button disabled={disableAddBlocks} icon="checklist" outlined style={{ marginRight: '10px', marginTop: '5px' }} onClick={handleAddTaskList}>
                            <Trans i18nKey="journey-step.blocks.task.add-button">Task</Trans>
                          </Button>
                        </WrapLockedAddBlocksTooltip>
                        <WrapLockedAddBlocksTooltip locked={disableAddBlocks}>
                          <Button disabled={disableAddBlocks} icon="bar_chart" outlined style={{ marginRight: '10px', marginTop: '5px' }} onClick={handleAddPoll}>
                            <Trans i18nKey="journey-step.blocks.poll.add-button">Poll</Trans>
                          </Button>
                        </WrapLockedAddBlocksTooltip>
                        <WrapLockedAddBlocksTooltip locked={disableAddBlocks}>
                          <Button disabled={disableAddBlocks} icon="view_list" outlined style={{ marginRight: '10px', marginTop: '5px' }} onClick={handleAddForm}>
                            <Trans i18nKey="journey-step.blocks.form.add-button">Form</Trans>
                          </Button>
                        </WrapLockedAddBlocksTooltip>
                        <WrapLockedAddBlocksTooltip locked={disableAddBlocks}>
                          <Button disabled={disableAddBlocks} icon="add_to_home_screen" outlined style={{ marginRight: '10px', marginTop: '5px' }} onClick={handleAddPortal}>
                            <Trans i18nKey="journey-step.blocks.portal.add-button">Portal</Trans>
                          </Button>
                        </WrapLockedAddBlocksTooltip>
                        <WrapLockedAddBlocksTooltip locked={disableAddBlocks}>
                          <Button disabled={disableAddBlocks} icon="format_list_numbered" outlined onClick={handleAddSequence} style={{ marginTop: '5px' }}>
                            <Trans i18nKey="journey-step.blocks.sequence.add-button">Sequence</Trans>
                          </Button>
                        </WrapLockedAddBlocksTooltip>
                      </div>
                    </GridCell>
                    <GridCell span={12}>
                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                      {blockFields.map((block: any, index: number) => (
                        <Elevation
                          key={block.id}
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRadius: '4px',
                            margin: '-10px',
                            backgroundColor: '#fff',
                            borderTop: index === 0 ? '1px solid rgba(0, 0, 0, 0.12)' : 'none'
                          }}
                        >
                          {block.type === MessageBlocksTypes.Poll && (
                            <PollBlockType
                              control={control}
                              block={block}
                              channelValue={channelValue}
                              index={index}
                              blocksLength={blockFieldsLength}
                              errors={errors}
                              isLoading={!!isLoading}
                              handleRemoveBlock={handleRemoveBlock}
                              handleMoveUpBlock={handleMoveUpBlock}
                              handleMoveDownBlock={handleMoveDownBlock}
                              handleAddPoll={handleAddPoll}
                              handleOpenPreviewPopup={handleOpenPreviewPopup}
                            />
                          )}

                          {block.type === MessageBlocksTypes.CallToAction && (
                            <CallToActionBlockType control={control} block={block} index={index} errors={errors} isLoading={!!isLoading} handleRemoveBlock={handleRemoveBlock} />
                          )}

                          {block.type === MessageBlocksTypes.PortalLink && (
                            <PortalLinkBlockType control={control} block={block} index={index} errors={errors} isLoading={!!isLoading} handleRemoveBlock={handleRemoveBlock} />
                          )}

                          {block.type === MessageBlocksTypes.Task && (
                            <TaskBlockType control={control} block={block} index={index} errors={errors} isLoading={!!isLoading} handleRemoveBlock={handleRemoveBlock} />
                          )}
                          {block.type === MessageBlocksTypes.Form && (
                            <FormBlockType
                              control={control}
                              block={block}
                              index={index}
                              blocksLength={blockFieldsLength}
                              errors={errors}
                              isLoading={!!isLoading}
                              handleRemoveBlock={handleRemoveBlock}
                              handleMoveUpBlock={handleMoveUpBlock}
                              handleMoveDownBlock={handleMoveDownBlock}
                              handleAddForm={handleAddForm}
                              handleOpenPreviewPopup={handleOpenPreviewPopup}
                            />
                          )}
                          {block.type === MessageBlocksTypes.Sequence && (
                            <SequenceBlockType
                              control={control}
                              block={block}
                              index={index}
                              blocksLength={blockFieldsLength}
                              errors={errors}
                              isLoading={!!isLoading}
                              handleRemoveBlock={handleRemoveBlock}
                              handleMoveUpBlock={handleMoveUpBlock}
                              handleMoveDownBlock={handleMoveDownBlock}
                              handleAddSequence={handleAddSequence}
                              handleOpenPreviewPopup={handleOpenPreviewPopup}
                            />
                          )}
                        </Elevation>
                      ))}
                    </GridCell>
                  </GridRow>
                </GridCell>
              </GridRow>
            </GridCell>
          </GridRow>
        </GridCell>
        {Object.keys(errors).length > 0 && (
          <GridCell span={12} style={{ textAlign: 'right' }}>
            <Typography use="body1" tag="p" theme="error" className="field-validation-text">
              <Trans i18nKey="crud.form.errors">There are field validations. Check the fields for more details.</Trans>
            </Typography>
          </GridCell>
        )}
        <GridCell span={12} style={{ textAlign: 'right' }}>
          <Button type="button" onClick={onCancel} style={{ marginRight: '1.5rem' }}>
            <Trans i18nKey="crud.cancel">Cancel</Trans>
          </Button>
          <Button type="button" onClick={handleSubmit(onSubmit)} raised {...(isLoading ? { icon: <CircularProgress theme="secondary" /> } : {})} style={{ minWidth: '150px' }}>
            {defaultValues && defaultValues.id ? <Trans i18nKey="journey-step.save-update">Save</Trans> : <Trans i18nKey="journey-step.save-new">Save</Trans>}
          </Button>
        </GridCell>
      </GridRow>
    </form>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function organizeMessageBlocks(messageBlocksWatch: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return blockFields.map((field: any, index: number) => {
      const fieldWatch = messageBlocksWatch?.[index];
      if (fieldWatch) {
        // verify if field being watched is not corrupted after last forms change (probably a moveBlock)
        if (!Object.prototype.hasOwnProperty.call(fieldWatch, 'question') && !Object.prototype.hasOwnProperty.call(fieldWatch, 'start_button')) {
          return { ...field };
        }

        // verify if options array or any position of array is null on the last forms change (probably a moveBlock)
        if (fieldWatch.options) {
          for (const option of fieldWatch.options) {
            if (!option) {
              return { ...field };
            }
          }
        }

        if (field.group) {
          const groupWatch = messageBlocksWatch?.[index]?.group;
          if (groupWatch) {
            for (const groupFieldIndex in groupWatch) {
              const groupField = groupWatch[groupFieldIndex];
              // verify if any index on group array of watched field is null or corrupted after last forms change (probably a moveBlock)
              if (groupField) {
                if (!Object.prototype.hasOwnProperty.call(groupField, 'question')) {
                  return { ...field };
                }

                if (groupField.options) {
                  for (const fieldGroupOption of groupField.options) {
                    if (fieldGroupOption == null) {
                      const watchedProperties = (({ question, field_type, description, required, multiple }) => ({ question, field_type, description, required, multiple }))(
                        messageBlocksWatch?.[index]?.group?.[groupFieldIndex]
                      );
                      const noNullOptionsOnGroupField = { ...field };
                      const correctOptionsArrayGroupField = noNullOptionsOnGroupField.group[groupFieldIndex].options;
                      const correctIdGroupField = noNullOptionsOnGroupField.group[groupFieldIndex].id;
                      noNullOptionsOnGroupField.group[groupFieldIndex] = { ...watchedProperties, options: correctOptionsArrayGroupField, id: correctIdGroupField };
                      return { ...noNullOptionsOnGroupField };
                    }
                  }
                }
              } else {
                // keeps the group array from blockFields and is merged with the watched array properties
                const watchedProperties = (({ question, start_button, field_type, description }) => ({ question, start_button, field_type, description }))(
                  messageBlocksWatch?.[index]
                );
                return { ...field, ...watchedProperties };
              }
            }
          }
        }
      }

      return {
        ...field,
        ...messageBlocksWatch?.[index]
      };
    });
  }
}

function StepCommunicationChannelsOptions({
  name,
  value,
  onBlur,
  onChange,
  ref,
  errors,
  isLoading
}: {
  name: string;
  value: string;
  onBlur: () => void;
  onChange: (...event: unknown[]) => void;
  ref: React.MutableRefObject<HTMLInputElement>;
  errors: FieldErrors<FieldValues>;
  isLoading: boolean | null | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  const userAuthCompanyId = authService.tenantId();
  const isDemo = CompanyService.isDemo(userAuthCompanyId);
  const isAsaas = CompanyService.isAsaas(userAuthCompanyId);
  const isSollo = CompanyService.isSollo(userAuthCompanyId);
  const isAlifeNino = CompanyService.isAlifeNino(userAuthCompanyId);
  const isIRCE = CompanyService.isIRCE(userAuthCompanyId);

  return (
    <>
      <Typography use="body1" tag="p">
        <label htmlFor={name}>
          <Trans i18nKey="journey-step.form.channel.label">Select your communication channel</Trans>
        </label>
      </Typography>
      <Radio
        name={name}
        value="EMAIL"
        checked={value === StepJourneyChannel.Email}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={ref}
        aria-invalid={errors?.channel?.message ? true : false}
        disabled={!!isLoading}
      >
        <Elevation
          z={2}
          className={styles.radioImageElevation + (value === StepJourneyChannel.Email ? ' selected' : '')}
          title={t('journey-step.form.channel.email.label', 'Email')}
        >
          <BadgeAnchor>
            <img src={Email} alt={t('journey-step.form.channel.email.label', 'Email')} />
            <Typography use="caption" tag="div" className={styles.radioImageElevationCaption}>
              <Trans i18nKey="journey-step.form.channel.email.label">Email</Trans>
            </Typography>
            {value === StepJourneyChannel.Email && (
              <Badge
                align="end"
                label={<Icon icon={{ icon: 'done', size: 'xsmall' }} style={{ fontSize: '0.8rem', marginTop: '5px' }} />}
                style={{ top: '0', backgroundColor: 'var(--mdc-theme-green-enabled)' }}
              />
            )}
          </BadgeAnchor>
        </Elevation>
      </Radio>
      <Radio
        name={name}
        value="SLACK"
        checked={value === 'SLACK'}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={ref}
        aria-invalid={errors?.channel?.message ? true : false}
        disabled={!!isLoading}
        style={isDemo || isAsaas || value === 'SLACK' ? {} : { display: 'none' }}
      >
        <Elevation z={2} className={styles.radioImageElevation + (value === 'SLACK' ? ' selected' : '')} title={t('journey-step.form.channel.slack.label', 'Slack')}>
          <BadgeAnchor>
            <img src={Slack} alt={t('journey-step.form.channel.slack.label', 'Slack')} />
            <Typography use="caption" tag="div" className={styles.radioImageElevationCaption}>
              <Trans i18nKey="journey-step.form.channel.slack.label">Slack</Trans>
            </Typography>
            {value === 'SLACK' && (
              <Badge
                align="end"
                label={<Icon icon={{ icon: 'done', size: 'xsmall' }} style={{ fontSize: '0.8rem', marginTop: '5px' }} />}
                style={{ top: '0', backgroundColor: 'var(--mdc-theme-green-enabled)' }}
              />
            )}
          </BadgeAnchor>
        </Elevation>
      </Radio>
      {(isSollo || value === 'BEEDOO') && (
        <Radio
          name={name}
          value="BEEDOO"
          checked={value === 'BEEDOO'}
          onBlur={onBlur}
          onChange={onChange}
          inputRef={ref}
          aria-invalid={errors?.channel?.message ? true : false}
          disabled={!!isLoading}
        >
          <Elevation z={2} className={styles.radioImageElevation + (value === 'BEEDOO' ? ' selected' : '')} title={t('journey-step.form.channel.beedoo.label', 'Beedoo')}>
            <BadgeAnchor>
              <img src={Beedoo} alt={t('journey-step.form.channel.beedoo.label', 'Beedoo')} />
              <Typography use="caption" tag="div" className={styles.radioImageElevationCaption}>
                <Trans i18nKey="journey-step.form.channel.beedoo.label">Beedoo</Trans>
              </Typography>
              {value === 'BEEDOO' && (
                <Badge
                  align="end"
                  label={<Icon icon={{ icon: 'done', size: 'xsmall' }} style={{ fontSize: '0.8rem', marginTop: '5px' }} />}
                  style={{ top: '0', backgroundColor: 'var(--mdc-theme-green-enabled)' }}
                />
              )}
            </BadgeAnchor>
          </Elevation>
        </Radio>
      )}

      <Radio
        name={name}
        value="WHATSAPP"
        checked={value === 'WHATSAPP'}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={ref}
        aria-invalid={errors?.channel?.message ? true : false}
        disabled={!!isLoading}
        style={isDemo || isAlifeNino || isIRCE || value === 'WHATSAPP' ? {} : { display: 'none' }}
      >
        <Elevation z={2} className={styles.radioImageElevation + (value === 'WHATSAPP' ? ' selected' : '')} title={t('journey-step.form.channel.whatsapp.label', 'WhatsApp')}>
          <BadgeAnchor>
            <img src={Whatsapp} alt={t('journey-step.form.channel.whatsapp.label', 'WhatsApp')} />
            <Typography use="caption" tag="div" className={styles.radioImageElevationCaption}>
              <Trans i18nKey="journey-step.form.channel.whatsapp.label">WhatsApp</Trans>
            </Typography>
            {value === 'WHATSAPP' && (
              <Badge
                align="end"
                label={<Icon icon={{ icon: 'done', size: 'xsmall' }} style={{ fontSize: '0.8rem', marginTop: '5px' }} />}
                style={{ top: '0', backgroundColor: 'var(--mdc-theme-green-enabled)' }}
              />
            )}
          </BadgeAnchor>
        </Elevation>
      </Radio>
      <Radio
        name={name}
        value="TEAMS"
        checked={value === 'TEAMS'}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={ref}
        aria-invalid={errors?.channel?.message ? true : false}
        disabled={!!isLoading}
        style={isDemo || value === 'TEAMS' ? {} : { display: 'none' }}
      >
        <Elevation z={2} className={styles.radioImageElevation + (value === 'TEAMS' ? ' selected' : '')} title={t('journey-step.form.channel.teams.label', 'Teams')}>
          <BadgeAnchor>
            <img src={Teams} alt={t('journey-step.form.channel.teams.label', 'Teams')} />
            <Typography use="caption" tag="div" className={styles.radioImageElevationCaption}>
              <Trans i18nKey="journey-step.form.channel.teams.label">Teams</Trans>
            </Typography>
            {value === 'TEAMS' && (
              <Badge
                align="end"
                label={<Icon icon={{ icon: 'done', size: 'xsmall' }} style={{ fontSize: '0.8rem', marginTop: '5px' }} />}
                style={{ top: '0', backgroundColor: 'var(--mdc-theme-green-enabled)' }}
              />
            )}
          </BadgeAnchor>
        </Elevation>
      </Radio>
      <div className="mdc-form-field">
        <NavLink to="/settings/integrations" target="_blank">
          <Elevation z={2} title={t('journey-step.form.channel.help') + ' ' + t('journey-step.form.channel.help_link')} theme="primary" className={styles.radioIconElevation}>
            <Icon icon={{ icon: 'add_circle', size: 'large' }} />
          </Elevation>
        </NavLink>
      </div>
    </>
  );
}

function SubjectDynamicFieldButton({ disabled, onSelectDynamicField }: { disabled: boolean; onSelectDynamicField: (key: string) => void }) {
  const [openDynamicFields, setOpenDynamicFields] = React.useState(false);

  function onSelectItem(event: MenuOnSelectEventT) {
    let key = '';
    switch (event.detail.index) {
      case 0:
        key = '@{Colaborador.PrimeiroNome}';
        break;
      case 1:
        key = '@{Colaborador.NomeCompleto}';
        break;
      case 2:
        key = '@{Colaborador.Apelido}';
        break;
      case 3:
        key = '@{Colaborador.DataPrimeiroDia}';
        break;
      case 4:
        key = '@{Colaborador.DataPrimeiroDiaExtenso}';
        break;
      case 5:
        key = '@{Colaborador.Cargo}';
        break;
      case 6:
        key = '@{Colaborador.Departamento}';
        break;
      case 7:
        key = '@{Colaborador.CentroCusto}';
        break;
      case 8:
        key = '@{Empresa.Nome}';
        break;
      case 9:
        key = '@{Empresa.NomeMascote}';
        break;
      case 10:
        key = '@{Liderança.PrimeiroNome}';
        break;
      case 11:
        key = '@{Liderança.Apelido}';
        break;
      case 12:
        key = '@{Buddy.PrimeiroNome}';
        break;
      case 13:
        key = '@{Buddy.Apelido}';
        break;
    }

    onSelectDynamicField(key);
  }

  return (
    <MenuSurfaceAnchor>
      <Menu open={openDynamicFields} onSelect={onSelectItem} onClose={() => setOpenDynamicFields(false)}>
        <MenuItem>
          <Trans i18nKey="mentions.fields.new_hire.first_name">@NewHire.FirstName</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.new_hire.full_name">@NewHire.FullName</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.new_hire.short_name">@NewHire.ShortName</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.new_hire.start_date">@NewHire.FirstDayDate</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.new_hire.start_date_full">@NewHire.FirstDayDateFull</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.new_hire.job_title">@NewHire.JobTitle</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.new_hire.department">@NewHire.Department</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.new_hire.cost_center">@NewHire.CostCenter</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.company.name">@Company.Name</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.company.avatar_name">@Company.AvatarName</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.leader.first_name">@Leader.FirstName</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.leader.short_name">@Leader.ShortName</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.buddy.first_name">@Buddy.FirstName</Trans>
        </MenuItem>
        <MenuItem>
          <Trans i18nKey="mentions.fields.buddy.short_name">@Buddy.ShortName</Trans>
        </MenuItem>
      </Menu>
      <Button disabled={disabled} style={{ float: 'right', fontSize: '80%', marginTop: '10px' }} onClick={() => setOpenDynamicFields(true)} trailingIcon="auto_awesome">
        Campo dinâmico
      </Button>
    </MenuSurfaceAnchor>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function RemirrorToolbarAndBody({ manager }: { manager: RemirrorManager<any> }) {
  const { t } = useTranslation();
  const [mentionSearch, setMentionSearch] = React.useState('');
  const [isEditingImage, setIsEditingImage] = React.useState(false);
  const { linkShortcut, isEditingLink, setIsEditingLink } = useLinkShortcut();

  const ALL_MENTIONS_USERS = [
    { id: 'newHireFirstName', label: t('mentions.fields.new_hire.first_name', '@NewHire.FirstName') },
    { id: 'newHireFullName', label: t('mentions.fields.new_hire.full_name', '@NewHire.FullName') },
    { id: 'newHireShortName', label: t('mentions.fields.new_hire.short_name', '@NewHire.ShortName') },
    { id: 'newHireWorkEmail', label: t('mentions.fields.new_hire.work_email', '@NewHire.WorkEmail') },
    { id: 'newHirePersonalEmail', label: t('mentions.fields.new_hire.personal_email', '@NewHire.PersonalEmail') },
    { id: 'newHireStartDate', label: t('mentions.fields.new_hire.start_date', '@NewHire.FirstDayDate') },
    { id: 'newHireStartDateDescription', label: t('mentions.fields.new_hire.start_date_full', '@NewHire.FirstDayDateFull') },
    { id: 'newHireJobTitle', label: t('mentions.fields.new_hire.job_title', '@NewHire.JobTitle') },
    { id: 'newHireDepartment', label: t('mentions.fields.new_hire.department', '@NewHire.Department') },
    { id: 'newHireCostCenter', label: t('mentions.fields.new_hire.cost_center', '@NewHire.CostCenter') },
    { id: 'leaderFirstName', label: t('mentions.fields.leader.first_name', '@Leader.FirstName') },
    { id: 'leaderFullName', label: t('mentions.fields.leader.full_name', '@Leader.FullName') },
    { id: 'leaderShortName', label: t('mentions.fields.leader.short_name', '@Leader.ShortName') },
    { id: 'leaderWorkEmail', label: t('mentions.fields.leader.work_email', '@Leader.WorkEmail') },
    { id: 'leaderJobTitle', label: t('mentions.fields.leader.job_title', '@Leader.JobTitle') },
    { id: 'leaderDepartment', label: t('mentions.fields.leader.department', '@Leader.Department') },
    { id: 'leaderCostCenter', label: t('mentions.fields.leader.cost_center', '@Leader.CostCenter') },
    { id: 'buddyFirstName', label: t('mentions.fields.buddy.first_name', '@Buddy.FirstName') },
    { id: 'buddyFullName', label: t('mentions.fields.buddy.full_name', '@Buddy.FullName') },
    { id: 'buddyShortName', label: t('mentions.fields.buddy.short_name', '@Buddy.ShortName') },
    { id: 'buddyWorkEmail', label: t('mentions.fields.buddy.work_email', '@Buddy.WorkEmail') },
    { id: 'buddyJobTitle', label: t('mentions.fields.buddy.job_title', '@Buddy.JobTitle') },
    { id: 'buddyDepartment', label: t('mentions.fields.buddy.department', '@Buddy.Department') },
    { id: 'buddyCostCenter', label: t('mentions.fields.buddy.cost_center', '@Buddy.CostCenter') },
    { id: 'companyName', label: t('mentions.fields.company.name', '@Company.Name') },
    { id: 'companyAvatarName', label: t('mentions.fields.company.avatar_name', '@Company.AvatarName') }
  ];

  const mentionItems = React.useMemo(() => {
    return ALL_MENTIONS_USERS.filter((user) => user.label.toLowerCase().includes(mentionSearch)).sort();
  }, [mentionSearch]);

  const onClickAddImageButton = (): void => {
    setIsEditingImage(true);
  };

  useEmoji();

  return (
    <>
      <div>
        <TopToolbar setIsEditingLink={setIsEditingLink} onClickAddImageButton={onClickAddImageButton} />
      </div>
      <EmojiPopupComponent />
      <EditorComponent />
      <FloatingLinkToolbar linkShortcut={linkShortcut} setIsEditingLink={setIsEditingLink} isEditingLink={isEditingLink} />
      <ImageToolbar isEditingImage={isEditingImage} setIsEditingImage={setIsEditingImage} manager={manager} />
      <MentionAtomPopupComponent onChange={(state): void => setMentionSearch(state?.query.full.toLowerCase() ?? '')} items={mentionItems} />
      {process.env.REACT_APP_ENV !== 'prod' && <ProsemirrorDevTools />}
    </>
  );
}

export function BlockTitleActions({
  children,
  index,
  handleRemoveBlock,
  handleMoveUpBlock,
  handleMoveDownBlock
}: {
  children: JSX.Element;
  index: number;
  handleMoveUpBlock: (index: number) => void;
  handleMoveDownBlock: (index: number) => void;
  handleRemoveBlock: (index: number, name: string) => void;
}): JSX.Element {
  return (
    <>
      <Typography use="body1" tag="p" style={{ marginTop: '0', display: 'inline-block' }}>
        {children}
      </Typography>
      <div style={{ float: 'right', marginTop: '-5px' }}>
        <ArrayFieldsActions index={index} handleMoveUpBlock={handleMoveUpBlock} handleMoveDownBlock={handleMoveDownBlock} handleRemoveBlock={handleRemoveBlock} />
      </div>
    </>
  );
}

export function ArrayFieldsActions({
  index,
  handleRemoveBlock,
  handleMoveUpBlock,
  handleMoveDownBlock
}: {
  index: number;
  handleMoveUpBlock: (index: number) => void;
  handleMoveDownBlock: (index: number) => void;
  handleRemoveBlock: (index: number, name: string) => void;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      {index > 0 && (
        <TooltipWrapper tooltipText={t('journey-step.form.blocks.move-up-button', 'Move upwards')}>
          <IconButton icon="expand_less" onClick={(): void => handleMoveUpBlock(index)} label={t('journey-step.form.blocks.move-up-button', 'Move upwards')} />
        </TooltipWrapper>
      )}
      <TooltipWrapper tooltipText={t('journey-step.form.blocks.move-down-button', 'Move down')}>
        <IconButton icon="expand_more" onClick={(): void => handleMoveDownBlock(index)} label={t('journey-step.form.blocks.move-down-button', 'Move downwards')} />
      </TooltipWrapper>
      <TooltipWrapper tooltipText={t('journey-step.form.blocks.delete-button', 'Delete')}>
        <IconButton
          icon="delete_forever"
          onClick={(): void => handleRemoveBlock(index, `${t('journey-step.blocks.poll.add-button')} ${index + 1}`)}
          label={t('journey-step.form.blocks.delete-button', 'Delete')}
        />
      </TooltipWrapper>
    </>
  );
}

function StepJourneyFormPeriodDaysLabel({ period }: { period: string }): JSX.Element {
  switch (period) {
    case 'fixed-date':
      return <Trans i18nKey="journey-step.form.period_fixed_date.label">What date?</Trans>;
    case 'first-day':
      return <Trans i18nKey="journey-step.form.period_days_first.label">No 1º dia</Trans>;
    case 'after-first-day':
      return <Trans i18nKey="journey-step.form.period_days_after.label">Quantos dias depois do 1º dia</Trans>;
    default:
      return <Trans i18nKey="journey-step.form.period_days_before.label">Quantos dias antes do 1º dia</Trans>;
    case 'last-day':
      return <Trans i18nKey="journey-step.form.period_days_last.label">No último dia</Trans>;
    case 'after-last-day':
      return <Trans i18nKey="journey-step.form.period_days_after_last.label">Quantos dias depois do último dia</Trans>;
    case 'before-last-day':
      return <Trans i18nKey="journey-step.form.period_days_before_last.label">Quantos dias antes do último dia</Trans>;
  }
}

function StepJourneyFormPeriodDaysSummary({
  periodValue,
  periodDaysValue,
  periodDateValue,
  periodTimeValue
}: {
  periodValue: string;
  periodDaysValue: string | undefined;
  periodDateValue: string | undefined;
  periodTimeValue: string | undefined;
}): JSX.Element {
  return (
    <>
      <hr />
      <strong>
        <Trans i18nKey="journey-step.form.period-summary.label">Sumário (quando):</Trans>
      </strong>{' '}
      {periodDaysValue || periodDateValue || periodValue === 'first-day' ? (
        <StepJourneyFormPeriodDaysSummaryDetails periodValue={periodValue} periodDaysValue={periodDaysValue} periodDateValue={periodDateValue} />
      ) : (
        '...'
      )}
      {periodTimeValue ? <Trans i18nKey="journey-step.form.period-summary.period_time">, às {{ time: periodTimeValue }} horas</Trans> : ''}
      <hr />
    </>
  );
}

function StepJourneyFormPeriodDaysSummaryDetails({
  periodValue,
  periodDaysValue,
  periodDateValue
}: {
  periodValue: string;
  periodDaysValue: string | undefined;
  periodDateValue: string | undefined;
}): JSX.Element {
  const count = periodDaysValue ? +periodDaysValue : 0;
  switch (periodValue) {
    case 'fixed-date':
      return (
        <>
          <Trans i18nKey="journey-step.form.period-summary.period_fixed_date">Send on</Trans> {dateFormat(periodDateValue)}
        </>
      );
    case 'first-day':
      return <Trans i18nKey="journey-step.form.period-summary.period_days_first">Send on First Day</Trans>;
    case 'after-first-day':
      return (
        <Trans i18nKey="journey-step.form.period-summary.period_days_after" count={count}>
          Send {{ count: count }} days after the first day of work
        </Trans>
      );
    default:
      return (
        <Trans i18nKey="journey-step.form.period-summary.period_days_before" count={count}>
          Send {{ count: count }} days before the first day of work
        </Trans>
      );
    case 'before-last-day':
      return (
        <Trans i18nKey="journey-step.form.period-summary.period_days_before_last_day" count={count}>
          Send {{ count: count }} days before the last day of work
        </Trans>
      );
    case 'after-last-day':
      return (
        <Trans i18nKey="journey-step.form.period-summary.period_days_after_last_day" count={count}>
          Send {{ count: count }} days after the last day of work
        </Trans>
      );
    case 'last-day':
      return <Trans i18nKey="journey-step.form.period-summary.period_days_last">Send on Last Day</Trans>;
  }
}

function InputFormField({
  children,
  inputName,
  inputLabel,
  inputLocked
}: {
  children: JSX.Element;
  inputName: string;
  inputLabel: JSX.Element;
  inputLocked: boolean;
}): JSX.Element {
  return (
    <WrapLockedEmailTooltip locked={inputLocked}>
      <>
        <Typography use="body1" theme={!inputLocked ? '' : 'textDisabledOnBackground'} tag="p">
          <label htmlFor={inputName}>
            {inputLabel}
            {!!inputLocked && <Icon icon={{ icon: 'lock', size: 'xsmall' }} style={{ verticalAlign: 'middle', margin: '0 5px', paddingBottom: '2px' }} />}
          </label>
        </Typography>
        <>{children}</>
      </>
    </WrapLockedEmailTooltip>
  );
}

function WrapLockedEmailTooltip({ children, locked }: { children: JSX.Element; locked: boolean }): JSX.Element {
  const { t } = useTranslation();
  const emailOnlyFieldText = t('journey-step.form.email-only-field', 'Option available only when communication channel is email.');

  return (
    <TooltipWrapper showTooltip={!!locked} tooltipText={emailOnlyFieldText}>
      {children}
    </TooltipWrapper>
  );
}

export function WrapLockedSlackTooltip({ children, locked }: { children: JSX.Element; locked: boolean }): JSX.Element {
  const { t } = useTranslation();
  const slackOnlyFieldText = t('journey-step.form.slack-only-field', 'Option available only when communication channel is Slack.');

  return (
    <TooltipWrapper showTooltip={!!locked} tooltipText={slackOnlyFieldText}>
      {children}
    </TooltipWrapper>
  );
}

function WrapLockedAddBlocksTooltip({ children, locked }: { children: JSX.Element; locked: boolean }): JSX.Element {
  const { t } = useTranslation();
  const slackOnlyFieldText = t('journey-step.form.disable-blocks-button', 'You can add only one block per journey step message. Delete the current block to add a different one.');

  return (
    <TooltipWrapper showTooltip={!!locked} tooltipText={slackOnlyFieldText}>
      {children}
    </TooltipWrapper>
  );
}

StepJourneyForm.propTypes = {
  journeyId: PropTypes.string.isRequired,
  journey: PropTypes.any.isRequired,
  stepId: PropTypes.string,
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultValues: PropTypes.any,
  isLoading: PropTypes.bool
};

export default StepJourneyForm;
