import React from 'react';
import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, Typography } from 'rmwc';

import styles from './StepJourneyPreview.module.css';
import StepJourneyMessagePreview from './StepJourneyMessagePreview';
import { Trans } from 'react-i18next';

function BlocksPreviewPopup({
  journeyId,
  stepId,
  messageChannel,
  messageToWhom,
  messageToWhomCopy,
  messageSubject,
  messageHtml,
  messageBlocks,
  whatsappTemplate,
  onClose
}: {
  journeyId: string;
  stepId: string | undefined;
  messageChannel: string | undefined;
  messageToWhom: string | undefined;
  messageToWhomCopy: string | undefined;
  messageSubject: string | undefined;
  messageHtml: string;
  messageBlocks: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  whatsappTemplate: string | undefined;
  onClose: () => void;
}): JSX.Element {
  const [open, setOpen] = React.useState(true);

  function onCloseDialog() {
    setOpen(false);
    onClose && onClose();
    return true;
  }

  return (
    <Dialog open={open} onClosed={onCloseDialog} className={styles.previewDialog}>
      <DialogTitle>
        <Typography use="headline6">
          <Trans i18nKey="journey-step.preview.popup-title">Step Visualization</Trans>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <StepJourneyMessagePreview
          loadFromFormEdit={true}
          journeyId={journeyId}
          stepId={stepId}
          messageChannel={messageChannel}
          messageToWhom={messageToWhom}
          messageToWhomCopy={messageToWhomCopy}
          messageSubject={messageSubject}
          messageHtml={messageHtml}
          messageBlocks={messageBlocks}
          whatsappTemplate={whatsappTemplate}
        />
      </DialogContent>
      <DialogActions>
        <DialogButton action="close" style={{ color: '#009688', marginRight: '10px' }}>
          <Trans i18nKey="crud.close">Close</Trans>
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}

export default BlocksPreviewPopup;
