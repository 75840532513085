import React from 'react';

import { GridCell, GridRow } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { DataTable, DataTableContent, DataTableHead, DataTableHeadCell, DataTableBody, DataTableRow, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import { Select, FormattedOption } from '@rmwc/select';
import '@rmwc/select/styles';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/styles';

import { Typography } from '@rmwc/typography';
import '@rmwc/typography/styles';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import { Dialog, DialogContent, DialogTitle } from 'rmwc';

import styles from '../Journey/StepJourneyPreview.module.css';

import { AxiosError } from 'axios';
import { UseMutationResult } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form';
import { dateFormatWeekdayText } from '../common/DatesFormat/dateFormatWeekdayText';
import { EmployeeJourneyStep, EmployeeJourneyStepStatus, StepEditableProps } from './EmployeeRepository';
import { StepJourneyChannelImage } from '../Journey/StepJourneyMessagePreview';
import { dateIsBeforeNow } from '../common/DatesFormat/dateIsBeforeNow';
import Message from '../../services/Message';
import { StepJourneyToCell } from './EmployeeViewProgress';
import { converteDateIsoToDatetimeLocal } from './EmployeeStartJourney';
import parseExtractHashId from '../../services/HttpRepository/parseExtractHashId';

const textColumnStyle = {
  textOverflow: 'unset',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
} as React.CSSProperties;

function EmployeeEditStepPopup({
  step,
  useUpdateStep,
  onClose
}: {
  step: EmployeeJourneyStep;
  useUpdateStep: UseMutationResult<any, AxiosError<unknown, any>, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);

  const onSubmit = async (data: StepEditableProps) => {
    const updatingMessageId = Message.loading(t('journey-step.edit-popup.message-alert.updating'));
    setSubmitting(true);
    try {
      const parsedId = parseExtractHashId(step.id || '');
      await useUpdateStep.mutateAsync({ id: step.people_id, stepId: parsedId, updateData: data });
      Message.dismiss(updatingMessageId);
      Message.success(t('journey-step.edit-popup.message-alert.success'));
    } catch (error) {
      console.log('error', error);
      Message.error(t('journey-step.edit-popup.message-alert.error'));
    }
    setSubmitting(false);
  };

  function onCloseDialog() {
    setOpen(false);
    onClose && onClose();
    return true;
  }

  return (
    <Dialog open={open} onClosed={onCloseDialog} className={styles.previewDialog}>
      <DialogTitle>
        <Typography use="headline6">{step.step_name}</Typography>
      </DialogTitle>
      <DialogContent>
        <EmployeeEditSentStepPopupTable step={step} submitting={submitting} onSubmit={onSubmit} onCancel={onCloseDialog} />
      </DialogContent>
    </Dialog>
  );
}

export function EmployeeEditSentStepPopupTable({
  step,
  submitting,
  onSubmit,
  onCancel
}: {
  step: EmployeeJourneyStep;
  submitting: boolean;
  onSubmit: (values: FieldValues) => Promise<void>;
  onCancel: () => void;
}): JSX.Element {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      schedule_date: converteDateIsoToDatetimeLocal(step.schedule_date),
      status: step.status
    }
  });
  const newStatus: string | undefined = useWatch({ control, name: 'status' });

  const disableNewScheduleDate = step.status == EmployeeJourneyStepStatus.Scheduled && newStatus == EmployeeJourneyStepStatus.Removed;

  return (
    <GridCell span={12}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridRow>
          <GridCell span={12}>
            <DataTable style={{ width: '100%' }}>
              <DataTableContent>
                <DataTableHead>
                  <DataTableRow>
                    <DataTableHeadCell>
                      <Trans i18nKey="employee.start-journey.table.step-channel">Channel</Trans>
                    </DataTableHeadCell>
                    <DataTableHeadCell>
                      <Trans i18nKey="employee.start-journey.table.step-to">To</Trans>
                    </DataTableHeadCell>
                    <DataTableHeadCell>
                      <Trans i18nKey="employee.start-journey.table.step-name">Message name</Trans>
                    </DataTableHeadCell>
                    <DataTableHeadCell>
                      <Trans i18nKey="employee.start-journey.table.status">Status</Trans>
                    </DataTableHeadCell>
                    <DataTableHeadCell>
                      <Trans i18nKey="employee.start-journey.table.scheduled-date">Scheduled</Trans>
                    </DataTableHeadCell>
                  </DataTableRow>
                </DataTableHead>
                <DataTableBody>
                  <DataTableRow key={step.id}>
                    <DataTableCell>
                      <StepJourneyChannelImage channel={step.step_channel} />
                    </DataTableCell>
                    <DataTableCell>
                      <StepJourneyToCell step={step} to={step.to_whom} />
                    </DataTableCell>
                    <DataTableCell style={textColumnStyle}>{step.step_name}</DataTableCell>
                    <DataTableCell>
                      <Controller
                        control={control}
                        defaultValue={step.status}
                        name="status"
                        render={({ onChange, onBlur, value, name }): React.ReactElement => (
                          <Select
                            id={name}
                            name={name}
                            options={setStatusOptions(step.status)}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            icon={value === EmployeeJourneyStepStatus.Removed ? 'warning_amber' : 'alarm_on'}
                            className={value !== EmployeeJourneyStepStatus.Scheduled ? 'select-field-warning' : ''}
                          />
                        )}
                      />
                    </DataTableCell>
                    <DataTableCell>
                      <Controller
                        control={control}
                        defaultValue={converteDateIsoToDatetimeLocal(step.schedule_date)}
                        name="schedule_date"
                        render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
                          <>
                            <TextField
                              type="datetime-local"
                              disabled={disableNewScheduleDate || false}
                              id={name}
                              name={name}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              inputRef={ref}
                            />
                            {!disableNewScheduleDate && (
                              <Typography use="caption" tag="div">
                                {dateIsBeforeNow(value) ? (
                                  <Icon
                                    icon={{ icon: 'warning', size: 'xsmall' }}
                                    style={{ fontSize: '0.8rem', marginTop: '-2px', marginRight: '2px', verticalAlign: 'middle', color: 'var(--mdc-theme-error)' }}
                                    title={t('employee.start-journey.status.date-past-warning', 'Date in the past. It will not be sent automatically.')}
                                  />
                                ) : null}
                                {dateFormatWeekdayText(value)}
                              </Typography>
                            )}
                          </>
                        )}
                      />
                    </DataTableCell>
                  </DataTableRow>
                </DataTableBody>
              </DataTableContent>
            </DataTable>
          </GridCell>
          <GridCell span={12} style={{ textAlign: 'right' }}>
            <Button type="button" onClick={onCancel} style={{ marginRight: '1.5rem' }}>
              <Trans i18nKey="crud.cancel">Cancel</Trans>
            </Button>
            <Button type="submit" raised disabled={submitting} {...(submitting ? { icon: <CircularProgress theme="secondary" /> } : {})}>
              <Trans i18nKey="journey-step.edit-popup.save-changes">Save changes</Trans>
            </Button>
          </GridCell>
        </GridRow>
      </form>
    </GridCell>
  );
}

function setStatusOptions(status: EmployeeJourneyStepStatus): FormattedOption[] {
  const { t } = useTranslation();

  if (status == EmployeeJourneyStepStatus.Bounced || status == EmployeeJourneyStepStatus.Error) {
    const statusErrorOptions = [
      { value: EmployeeJourneyStepStatus.Error, label: t('employee.start-journey.status.error', 'Error') },
      { value: EmployeeJourneyStepStatus.Bounced, label: t('employee.start-journey.status.bounced', 'Bounced') },
      { value: EmployeeJourneyStepStatus.Scheduled, label: t('employee.start-journey.status.schedule', 'Schedule') }
    ] as FormattedOption[];
    return statusErrorOptions;
  }

  if (status == EmployeeJourneyStepStatus.Removed) {
    const statusRemovedOptions = [
      { value: EmployeeJourneyStepStatus.Removed, label: t('employee.start-journey.status.remove', 'Remove') },
      { value: EmployeeJourneyStepStatus.Scheduled, label: t('employee.start-journey.status.schedule', 'Schedule') }
    ] as FormattedOption[];
    return statusRemovedOptions;
  }

  const statusScheduledOptions = [
    { value: EmployeeJourneyStepStatus.Scheduled, label: t('employee.start-journey.status.schedule', 'Schedule') },
    { value: EmployeeJourneyStepStatus.Removed, label: t('employee.start-journey.status.remove', 'Remove') }
  ] as FormattedOption[];

  return statusScheduledOptions;
}

export default EmployeeEditStepPopup;
