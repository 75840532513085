import { FieldValues } from 'react-hook-form';
import HttpBaseService from '../../services/HttpBaseService';
import JourneyRepository, { Journey } from './JourneyRepository';
import { useQuery, UseQueryResult } from 'react-query';

class JourneyService extends HttpBaseService<Journey> {
  constructor(private journeyRepository = new JourneyRepository()) {
    super('journeys', journeyRepository);
  }

  public useCreateJourneyCopy(id: string): UseQueryResult {
    return useQuery(
      'journey-steps',
      async () => {
        return this.journeyRepository.createJourneyCopy(id);
      },
      {
        refetchOnWindowFocus: false
      }
    );
  }

  convertValuesToModel(values: FieldValues): Journey {
    return {
      flow_type: values.flow_type,
      name: values.name,
      description: values.description
    } as Journey;
  }
}

export default JourneyService;
