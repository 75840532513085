import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useParams, withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import EmployeeService from './EmployeeService';

import { Grid, GridCell, GridRow } from '@rmwc/grid';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import { DataTable, DataTableContent, DataTableHead, DataTableHeadCell, DataTableBody, DataTableRow, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { Button } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import { Select, FormattedOption } from '@rmwc/select';
import '@rmwc/select/styles';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/styles';

import { Typography } from '@rmwc/typography';
import '@rmwc/typography/styles';

import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';

import LoadingModal from '../LoadingModal';
import ErrorMessage from '../ErrorMessage';
import Breadcrumb from '../common/Breadcrumb';
import { EmployeeJourneyStep, EmployeeJourneyStepStatus } from './EmployeeRepository';
import { dateFormatWeekdayText } from '../common/DatesFormat/dateFormatWeekdayText';
import { Controller, FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { StepJourneyChannelImage } from '../Journey/StepJourneyMessagePreview';
import Message from '../../services/Message';
import { dateIsBeforeNow } from '../common/DatesFormat/dateIsBeforeNow';
import { StepJourneyToCell } from './EmployeeViewProgress';

const textColumnStyle = {
  textOverflow: 'unset',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
} as React.CSSProperties;

function EmployeeStartJourney({ history }: InferProps<typeof EmployeeStartJourney.propTypes>): JSX.Element {
  const { t } = useTranslation();
  const { id, journeyId } = useParams() as { id: string; journeyId: string };
  const [submitting, setSubmitting] = React.useState(false);

  const employeeService = new EmployeeService();
  const useCreateStartJourney = employeeService.useCreateStartJourney();

  const { data, isFetching, isError, error } = employeeService.useListStartJourney(id, journeyId);

  if (isError) {
    return <ErrorMessage error={error}></ErrorMessage>;
  }

  if (isFetching) {
    return <LoadingModal open={true} />;
  }

  const onCancel = async (): Promise<void> => {
    history && history.goBack();
  };

  const onSubmit = async (values: FieldValues): Promise<void> => {
    setSubmitting(true);
    const mergedValues = values.data.map((field: EmployeeJourneyStep, index: number) => {
      return {
        ...data?.[index],
        ...field
      };
    });

    try {
      await useCreateStartJourney.mutateAsync({ id, journeyId, journeySteps: mergedValues });
      Message.success(t('employee.start-journey.started-success'));
      history.push(`/employees/${id}/progress`);
    } catch (error) {
      setSubmitting(false);
      console.error('Error on create start new journey: ', error);
      Message.error(t('employee.start-journey.started-error'));
    }
  };

  return (
    <Grid>
      <GridCell span={12}>
        <Breadcrumb
          routeSegments={[
            { name: t('navlink.employees'), path: '/employees' },
            { name: t('employee.view-title'), path: `/employees/${id}` },
            { name: t('employee.start-journey-title') }
          ]}
        />
      </GridCell>
      <GridCell span={12} align="middle">
        <h2>
          <Trans i18nKey="employee.start-journey.title-form">Start journey</Trans>
        </h2>
      </GridCell>

      <EmployeeStartJourneyTable data={data || []} submitting={submitting} onSubmit={onSubmit} onCancel={onCancel} />
    </Grid>
  );
}

export function EmployeeStartJourneyTable({
  data,
  submitting,
  totalEmployees,
  onCancel,
  onSubmit
}: {
  data: EmployeeJourneyStep[];
  submitting: boolean;
  totalEmployees?: number;
  onCancel: () => void;
  onSubmit: (values: FieldValues) => Promise<void>;
}): JSX.Element {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({ defaultValues: { data } });
  const { fields } = useFieldArray<EmployeeJourneyStep>({ name: 'data', control });

  const statusOptions = [
    { value: EmployeeJourneyStepStatus.Scheduled, label: t('employee.start-journey.status.schedule', 'Schedule') },
    { value: EmployeeJourneyStepStatus.Removed, label: t('employee.start-journey.status.remove', 'Remove') }
  ] as FormattedOption[];

  return (
    <GridCell span={12}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridRow>
          <GridCell span={12}>
            <DataTable style={{ width: '100%' }}>
              <DataTableContent>
                <DataTableHead>
                  <DataTableRow>
                    <DataTableHeadCell>
                      <Trans i18nKey="employee.start-journey.table.step-channel">Channel</Trans>
                    </DataTableHeadCell>
                    <DataTableHeadCell>
                      <Trans i18nKey="employee.start-journey.table.step-to">To</Trans>
                    </DataTableHeadCell>
                    <DataTableHeadCell>
                      <Trans i18nKey="employee.start-journey.table.step-name">Message name</Trans>
                    </DataTableHeadCell>
                    <DataTableHeadCell>
                      <Trans i18nKey="employee.start-journey.table.status">Status</Trans>
                    </DataTableHeadCell>
                    <DataTableHeadCell>
                      <Trans i18nKey="employee.start-journey.table.scheduled-date">Scheduled</Trans>
                    </DataTableHeadCell>
                  </DataTableRow>
                </DataTableHead>
                <DataTableBody>
                  {fields.map((stepItem, index: number) => (
                    <DataTableRow key={stepItem.id}>
                      <DataTableCell>
                        <StepJourneyChannelImage channel={stepItem.step_channel} />
                      </DataTableCell>
                      <DataTableCell>
                        <StepJourneyToCell step={stepItem} to={stepItem.to_whom} />
                      </DataTableCell>
                      <DataTableCell style={textColumnStyle}>{stepItem.step_name}</DataTableCell>
                      <DataTableCell>
                        <Controller
                          control={control}
                          defaultValue={stepItem.status || ''}
                          name={`data.${index}.status`}
                          render={({ onChange, onBlur, value, name }): React.ReactElement => (
                            <Select
                              id={name}
                              name={name}
                              options={statusOptions}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              icon={value === EmployeeJourneyStepStatus.Removed ? 'warning_amber' : 'alarm_on'}
                              className={value !== EmployeeJourneyStepStatus.Scheduled ? 'select-field-warning' : ''}
                            />
                          )}
                        />
                      </DataTableCell>
                      <DataTableCell>
                        <Controller
                          control={control}
                          defaultValue={converteDateIsoToDatetimeLocal(stepItem.schedule_date)}
                          name={`data.${index}.schedule_date`}
                          render={({ onChange, onBlur, value, name, ref }): React.ReactElement => (
                            <>
                              <TextField type="datetime-local" id={name} name={name} onBlur={onBlur} onChange={onChange} value={value} inputRef={ref} />
                              <Typography use="caption" tag="div">
                                {dateIsBeforeNow(value) ? (
                                  <Icon
                                    icon={{ icon: 'warning', size: 'xsmall' }}
                                    style={{ fontSize: '0.8rem', marginTop: '-2px', marginRight: '2px', verticalAlign: 'middle', color: 'var(--mdc-theme-error)' }}
                                    title={t('employee.start-journey.status.date-past-warning', 'Date in the past. It will not be sent automatically.')}
                                  />
                                ) : null}
                                {dateFormatWeekdayText(value)}
                              </Typography>
                            </>
                          )}
                        />
                      </DataTableCell>
                    </DataTableRow>
                  ))}
                </DataTableBody>
              </DataTableContent>
            </DataTable>
          </GridCell>
          <GridCell span={12} style={{ textAlign: 'right' }}>
            <Button type="button" onClick={onCancel} style={{ marginRight: '1.5rem' }}>
              <Trans i18nKey="crud.cancel">Cancel</Trans>
            </Button>
            <Button type="submit" raised disabled={submitting} {...(submitting ? { icon: <CircularProgress theme="secondary" /> } : {})}>
              {totalEmployees ? (
                <Trans i18nKey="employee.start-journey.batch.start-journey-employees" count={totalEmployees}>
                  Start journey for {{ count: totalEmployees }} people
                </Trans>
              ) : (
                <Trans i18nKey="employee.start-journey.form.save-update">Start journey</Trans>
              )}
            </Button>
          </GridCell>
        </GridRow>
      </form>
    </GridCell>
  );
}

// Must have the format: yyyy-MM-ddTHH:mm
export function converteDateIsoToDatetimeLocal(dateISO: string | undefined): string {
  if (!dateISO) {
    return '';
  }

  const date = new Date(dateISO);
  const parsedDate = date.toLocaleDateString('pt-BR', {
    timeZone: 'America/Sao_Paulo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });
  const parsedDateComa = parsedDate.replaceAll(',', '');
  const parsedDateSplit = parsedDateComa.split(' '); // 08/07/2022 09:00 or 11/02/2023, 09:00
  const parsedDateSplitDate = parsedDateSplit[0]; // 08/07/2022
  const parsedDateSplitTime = parsedDateSplit[1]; // 09:00

  const dateFormatSplit = parsedDateSplitDate.split('/');
  const dateFormatSplitDay = dateFormatSplit[0];
  const dateFormatSplitMonth = dateFormatSplit[1];
  const dateFormatSplitYear = dateFormatSplit[2];

  return `${dateFormatSplitYear}-${dateFormatSplitMonth}-${dateFormatSplitDay}T${parsedDateSplitTime}`;
}

EmployeeStartJourney.propTypes = {
  history: PropTypes.any
};

export default withRouter(EmployeeStartJourney);
