import React from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ListDivider, Button, Typography, Select, Elevation } from 'rmwc';
import { StepJourneyChannel } from '../../StepJourneyRepository';
import { fullWithStyle, BlockTitleActions } from '../../StepJourneyForm';
import { ScoreOptions } from '../FieldTypes/ScoreOptions';
import { LikertAgreementOptions } from '../FieldTypes/LikertAgreementOptions';
import { TextQuestion } from '../FieldTypes/TextQuestion';
import { SingleRatioOptions } from '../FieldTypes/SingleRatioOptions';
import { AskFollowUpQuestion } from '../FieldTypes/AskFollowUpQuestion';
import { RequiredFieldSwitch } from '../FieldTypes/RequiredFieldSwitch';

export enum MessageBlocksPollTypes {
  Text = 'TEXT',
  ENPS = 'ENPS',
  SingleRatio = 'SINGLE-RATIO',
  Score = 'SCORE',
  Likert = 'LIKERT-AGREEMENT'
}

export function PollBlockType({
  control,
  block,
  channelValue,
  index,
  blocksLength,
  errors,
  isLoading,
  handleRemoveBlock,
  handleMoveUpBlock,
  handleMoveDownBlock,
  handleAddPoll,
  handleOpenPreviewPopup
}: {
  control: Control;
  block: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  channelValue: string | undefined;
  index: number;
  blocksLength: number;
  errors: FieldErrors;
  isLoading: boolean;
  handleRemoveBlock: (index: number, name: string) => void;
  handleMoveUpBlock: (index: number) => void;
  handleMoveDownBlock: (index: number) => void;
  handleAddPoll: () => void;
  handleOpenPreviewPopup: () => void;
}): JSX.Element {
  const { t } = useTranslation();

  const blockPollTypeOptions = [
    {
      label: t('journey-step.form.blocks.poll.type.likert-agreement', 'Likert agreement scale'),
      value: MessageBlocksPollTypes.Likert
    },
    {
      label: t('journey-step.form.blocks.poll.type.score', 'Score options'),
      value: MessageBlocksPollTypes.Score
    },
    {
      label: t('journey-step.form.blocks.poll.type.single-ratio', 'Single choice'),
      value: MessageBlocksPollTypes.SingleRatio
    },
    {
      label: t('journey-step.form.blocks.poll.type.enps', 'eNPS'),
      value: MessageBlocksPollTypes.ENPS
    },
    {
      label: t('journey-step.form.blocks.poll.type.text', 'Text'),
      value: MessageBlocksPollTypes.Text
    }
  ];

  const showStartButtonText = channelValue !== StepJourneyChannel.Email;

  const [showSignLanguageVideoInput, setShowSignLanguageVideoInput] = React.useState(false);

  function handleSetShowSignLanguageVideoInput() {
    setShowSignLanguageVideoInput(!showSignLanguageVideoInput);
  }

  return (
    <>
      <div style={{ padding: '0 10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {index === 0 && (
          <>
            <Typography use="body1" tag="p" style={{ margin: '0', padding: '10px 0' }}>
              <Trans i18nKey="journey-step.blocks.poll.title">Poll</Trans>
            </Typography>
            <Button raised trailingIcon="visibility" onClick={handleOpenPreviewPopup}>
              <Trans i18nKey="journey-step.preview.open-button-label">Visualize</Trans>
            </Button>
          </>
        )}
      </div>

      {index === 0 && <ListDivider />}

      <div style={{ padding: '10px' }}>
        {showStartButtonText && index === 0 && (
          <div style={{ paddingBottom: '10px' }}>
            <TextQuestion
              control={control}
              property="start_button"
              labelProps={{
                labelText: 'Email button label',
                htmlFor: `message_blocks.${index}.start_button`,
                i18nKey: 'journey-step.form.blocks.poll.start_button.label'
              }}
              controllerName={`message_blocks.${index}.start_button`}
              invalid={errors?.message_blocks?.[index]?.start_button?.message}
              block={block}
              isLoading={isLoading}
              requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.start_button.required', requiredText: 'Email button label is required.' }}
              placeholder={t('journey-step.form.blocks.poll.start_button.placeholder')}
            />
          </div>
        )}

        <Elevation
          key={block.id}
          style={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '4px',
            marginTop: '10px',
            padding: '15px',
            borderLeftColor: 'var(--mdc-theme-primary)',
            borderLeftWidth: '2px'
          }}
        >
          <BlockTitleActions index={index} handleRemoveBlock={handleRemoveBlock} handleMoveUpBlock={handleMoveUpBlock} handleMoveDownBlock={handleMoveDownBlock}>
            <label htmlFor={`message_blocks.${index}.poll_type`}>
              <Trans i18nKey="journey-step.form.blocks.poll.type.label">Poll type</Trans> {index + 1}
            </label>
          </BlockTitleActions>

          <Controller
            control={control}
            defaultValue={block.poll_type || 'SCORE'}
            name={`message_blocks.${index}.poll_type`}
            rules={{ required: t('journey-step.form.blocks.poll.type.required', 'Poll type is required') as string }}
            render={({ onChange, onBlur, value, name }): React.ReactElement => (
              <>
                <Select
                  enhanced
                  options={blockPollTypeOptions}
                  id={name}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  placeholder={t('journey-step.form.blocks.poll.type.placeholder')}
                  icon="bar_chart"
                  invalid={errors?.message_blocks?.[index]?.poll_type?.message ? true : false}
                  aria-invalid={errors?.message_blocks?.[index]?.poll_type?.message ? true : false}
                  disabled={!!isLoading}
                  style={fullWithStyle}
                />

                {index === 0 && value === MessageBlocksPollTypes.Text && (
                  <TextQuestion
                    control={control}
                    property="start_button"
                    labelProps={{
                      labelText: 'Button label to start poll',
                      htmlFor: `message_blocks.${index}.start_button`,
                      i18nKey: 'journey-step.form.blocks.poll.start_button.label'
                    }}
                    controllerName={`message_blocks.${index}.start_button`}
                    invalid={errors?.message_blocks?.[index]?.start_button?.message}
                    block={block}
                    isLoading={isLoading}
                    requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.start_button.required', requiredText: 'Button label is required' }}
                    placeholder={t('journey-step.form.blocks.poll.start_button.placeholder')}
                  />
                )}

                <TextQuestion
                  control={control}
                  property="question"
                  labelProps={{
                    labelText: 'Question',
                    htmlFor: `message_blocks.${index}.question`,
                    i18nKey: 'journey-step.form.blocks.poll.question.label'
                  }}
                  controllerName={`message_blocks.${index}.question`}
                  invalid={errors?.message_blocks?.[index]?.question?.message}
                  block={block}
                  isLoading={isLoading}
                  requiredProps={{ i18nKey: 'journey-step.form.blocks.poll.question.required', requiredText: 'Question is required' }}
                  placeholder={t('journey-step.form.blocks.poll.question.placeholder')}
                  handleSetShowSignLanguageVideoInput={handleSetShowSignLanguageVideoInput}
                />

                {showSignLanguageVideoInput && (
                  <TextQuestion
                    control={control}
                    property="sign_language_url"
                    labelProps={{
                      labelText: 'Sign Language Video Link',
                      htmlFor: `message_blocks.${index}.sign_language_url`,
                      i18nKey: 'journey-step.form.blocks.form.sign_language_url.label'
                    }}
                    controllerName={`message_blocks.${index}.sign_language_url`}
                    invalid={errors?.message_blocks?.[index]?.sign_language_url?.message}
                    block={block}
                    isLoading={isLoading}
                  />
                )}

                {value === MessageBlocksPollTypes.SingleRatio && <SingleRatioOptions control={control} block={block} index={index} errors={errors} isLoading={!!isLoading} />}

                {value === MessageBlocksPollTypes.Likert && <LikertAgreementOptions control={control} block={block} index={index} errors={errors} isLoading={!!isLoading} />}

                {value === MessageBlocksPollTypes.Score && <ScoreOptions control={control} block={block} index={index} errors={errors} isLoading={!!isLoading} />}

                <RequiredFieldSwitch control={control} controllerName={`message_blocks.${index}.required`} block={block} />
              </>
            )}
          />

          <AskFollowUpQuestion control={control} block={block} index={index} errors={errors} isLoading={isLoading} />
        </Elevation>
        {blocksLength === index + 1 && (
          <Button outlined onClick={handleAddPoll} style={{ width: '100%', marginTop: '10px' }}>
            <Trans i18nKey="journey-step.form.blocks.add-question">Add another question</Trans>
          </Button>
        )}
      </div>
    </>
  );
}
